<template>
    <div class="row justify-center" @input="resultDomande()"
        v-if="domande_caricate">

        <div class="col-12 col-md-10" align="left">
            <legend> <strong>Proponente - Informazioni generali</strong> </legend>

            <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Cognome, Nome / Ragione Sociale</q-item-label>
                        <q-item-label>{{getDatiCliente("cognome")}} {{getDatiCliente("nome")}} {{getDatiCliente("ragione_sociale")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Indirizzo</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("indirizzo")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Civico</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("civico")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("comune")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("provincia")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>CAP</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("cap")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Codice Fiscale</q-item-label>
                        <q-item-label>{{getDatiCliente("codice_fiscale")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Partita IVA</q-item-label>
                        <q-item-label>{{getDatiCliente("partita_iva")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune di nascita</q-item-label>
                        <q-item-label>{{getDatiCliente("comune_nascita")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia di nascita</q-item-label>
                        <q-item-label>{{getDatiCliente("provincia_nascita")}}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
            <br>

            <div v-if="isMultirischioCasa">
                <fieldset>
                    <legend>Multirischio della casa</legend>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Tipologia di utilizzo dell'abitazione</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("tipo_dimora") }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Tipo di Abitazione</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipo_abitazione")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>A quale piano si trova l'Immobile?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("piano_abitazione")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    <!--    <q-item>
                            <q-item-section>
                                <q-item-label caption>Il Contraente &egrave; uguale all'Assicurato?</q-item-label>
                                <q-item-label>{{ ContraenteUgualeAssicurato }} </q-item-label>
                            </q-item-section>
                        </q-item> //-->
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Indirizzo dell'Immobile</q-item-label>
                                <q-item-label>{{ IndirizzoCasa }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Comune dell'Immobile</q-item-label>
                                <q-item-label>{{ ComuneCasa }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Cap dell'Immobile</q-item-label>
                                <q-item-label>{{ CapCasa }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                            <!--    <q-item-label caption>Provincia dell'Immobile</q-item-label>
                                <q-item-label>{{ ProvinciaCasa }} </q-item-label> //-->
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("provincia_fabbricato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("provincia_fabbricato")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Quanti sono i metri quadri dell`Immobile?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("metri_quadri")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_fabbricato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_fabbricato")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_ultima_ristrutturazione") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_ultima_ristrutturazione")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>

                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('multirischio_casa')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('multirischio_casa')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                       <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_in_corso") }} </q-item-label>
                            </q-item-section>
                            <div v-show=" havePolizzaStessoRischio === 'SI' ">
                                <q-item >
                                    <q-item-section>
                                        <q-item-label caption>Impresa di assicurazione</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('compagnia')}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption>Data di scadenza della copertura</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('scadenza_copertura')}}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_schifata") }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="this.PolizzaSchifataDaCompagnia === 'SI'">
                                <q-item-label caption>Breve descrizione del motivo del rifiuto</q-item-label>
                                <q-item-label>{{ domande_multirischio_casa.motivo_rifiuto_compagnia }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Si sono verificati sinistri negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_casa")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_casa')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_casa")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_casa')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_casa")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_casa')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_casa")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_casa')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>

                </fieldset>
            </div>

            <div v-if="isInfortuni">
                <fieldset>
                    <legend>Infortuni</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelDomandaInfortuni('contraente_uguale_assicurato') }}</q-item-label>
                                <q-item-label>{{getDomandaInfortuniValue("contraente_uguale_assicurato")}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getDomandaInfortuniValue('contraente_uguale_assicurato') === 'NO'">
                                <q-item-label caption>{{getLabelDomandaInfortuni("tipo_persona")}}</q-item-label>
                                <q-item-label>{{getDomandaInfortuniValue("tipo_persona")}}</q-item-label>
                            </q-item-section>
                            <template v-if="getDomandaInfortuniValue('contraente_uguale_assicurato') === 'NO' && getDomandaInfortuniValue('tipo_persona') === 'PERSONA_FISICA'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("cognome")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('cognome')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("nome")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('nome')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("sesso")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('sesso')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>

                        <template v-if="getDomandaInfortuniValue('contraente_uguale_assicurato') === 'NO' && getDomandaInfortuniValue('tipo_persona') === 'PERSONA_FISICA'">
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("data_nascita")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('data_nascita')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("stato_nascita_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('stato_nascita_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("comune_nascita_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('comune_nascita_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("cap_nascita_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('cap_nascita_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("provincia_nascita_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('provincia_nascita_infortuni')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("codice_fiscale")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('codice_fiscale')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("indirizzo_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('indirizzo_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("comune_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('comune_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("cap_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('cap_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("provincia_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('provincia_infortuni')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>

                        <template v-if="getDomandaInfortuniValue('contraente_uguale_assicurato') === 'NO' && getDomandaInfortuniValue('tipo_persona') === 'PROFESSIONISTA'">
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("cognome")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('cognome')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("nome")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('nome')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("partita_iva")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('partita_iva')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                        <template v-if="getDomandaInfortuniValue('contraente_uguale_assicurato') === 'NO' && getDomandaInfortuniValue('tipo_persona') === 'SOCIETA'">
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("ragione_sociale")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('ragione_sociale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("partita_iva")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('partita_iva')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("indirizzo_sede_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('indirizzo_sede_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("comune_sede_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('comune_sede_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("cap_sede_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('cap_sede_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaInfortuni("provincia_sede_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaInfortuniValue('provincia_sede_infortuni')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qual'è l'ttivit&agrave; svolta dall'Assicurato?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("attivita_infortuni")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>L`Assicurato ha più di 75 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("eta_assicurato_infortuni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('accertamenti_diagnostici')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("accertamenti_diagnostici")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('subito_ricoveri')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("subito_ricoveri")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('dipendenze')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("dipendenze")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('trauni_fratture')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("trauni_fratture")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('pensione_rendita_invalidita_infortunio')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("pensione_rendita_invalidita_infortunio")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('pensione_rendita_invalidita_malattia')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("pensione_rendita_invalidita_malattia")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('tipologia_copertura')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend><strong>Informazioni assicurative</strong></legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <q-item-label caption>{{getLabelDomandaInfortuni("motivo_rifiuto_compagnia")}}</q-item-label>
                                <q-item-label>{{getDomandaInfortuniValue('motivo_rifiuto_compagnia')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_infortuni')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_infortuni")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_infortuni')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_infortuni')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_infortuni")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_infortuni')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('infortuni')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('infortuni')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isMultirischioCommercio">
                <fieldset>
                    <legend>Multirischio del Commercio</legend>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("settore_merceologico_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("settore_merceologico_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("merce_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("merce_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("fatturato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("attivita_aggiuntiva_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("attivita_aggiuntiva_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('attivita_aggiuntiva_commercio')) === 'Si'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("settore_merceologico_aggiuntivo_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("settore_merceologico_aggiuntivo_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('attivita_aggiuntiva_commercio')) === 'Si'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("merce_commercio_aggiuntiva") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("merce_commercio_aggiuntiva")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_soci_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_soci_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("dipendenti_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("dipendenti_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('dipendenti_commercio')) === 'Si'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_dipendenti_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_dipendenti_commercio")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("materiale_costruzione_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("materiale_costruzione_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCommercio("numero_piani_edificio")}}</q-item-label>
                                <q-item-label>{{getDomandaCommercioValue('numero_piani_edificio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCommercio("metri_quadri")}}</q-item-label>
                                <q-item-label>{{getDomandaCommercioValue('metri_quadri')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCommercio("anno_costruzione")}}</q-item-label>
                                <q-item-label>{{getDomandaCommercioValue('anno_costruzione')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCommercio("ubicazione_diverso_sede")}}</q-item-label>
                                <q-item-label>{{getDomandaCommercioValue('ubicazione_diverso_sede')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="getDomandaCommercioValue('ubicazione_diverso_sede') === 'SI'">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCommercio("indirizzo_commercio")}}</q-item-label>
                                <q-item-label>{{getDomandaCommercioValue('indirizzo_commercio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCommercio("comune_commercio")}}</q-item-label>
                                <q-item-label>{{getDomandaCommercioValue('comune_commercio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCommercio("cap_commercio")}}</q-item-label>
                                <q-item-label>{{getDomandaCommercioValue('cap_commercio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCommercio("provincia_commercio")}}</q-item-label>
                                <q-item-label>{{getDomandaCommercioValue('provincia_commercio')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend><strong>Informazioni assicurative</strong></legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("motivo_rifiuto_compagnia") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("motivo_rifiuto_compagnia")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_commercio')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_commercio")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_commercio')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_commercio")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_commercio')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_commercio")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_commercio')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_commercio")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_commercio')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('multirischio_commercio')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('multirischio_commercio')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isMultirischioArtigiano">
                <fieldset>
                    <legend>Multirischio dell`Artigianato</legend>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("settore_merceologico") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("settore_merceologico")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("merce_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("merce_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("fatturato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_soci_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_soci_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("dipendenti_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("dipendenti_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('dipendenti_artigiano')) === 'Si'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_dipendenti_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_dipendenti_artigiano")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("materiale_costruzione_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("materiale_costruzione_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaArtigiano("numero_piani_edificio")}}</q-item-label>
                                <q-item-label>{{getDomandaArtigianoValue('numero_piani_edificio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaArtigiano("metri_quadri")}}</q-item-label>
                                <q-item-label>{{getDomandaArtigianoValue('metri_quadri')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaArtigiano("anno_costruzione")}}</q-item-label>
                                <q-item-label>{{getDomandaArtigianoValue('anno_costruzione')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaArtigiano("ubicazione_diverso_sede")}}</q-item-label>
                                <q-item-label>{{getDomandaArtigianoValue('ubicazione_diverso_sede')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="getDomandaArtigianoValue('ubicazione_diverso_sede') === 'SI'">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaArtigiano("indirizzo_artigiano")}}</q-item-label>
                                <q-item-label>{{getDomandaArtigianoValue('indirizzo_artigiano')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaArtigiano("comune_artigiano")}}</q-item-label>
                                <q-item-label>{{getDomandaArtigianoValue('comune_artigiano')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaArtigiano("cap_artigiano")}}</q-item-label>
                                <q-item-label>{{getDomandaArtigianoValue('cap_artigiano')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaArtigiano("provincia_artigiano")}}</q-item-label>
                                <q-item-label>{{getDomandaArtigianoValue('provincia_artigiano')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend><strong>Informazioni assicurative</strong></legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <q-item-label caption>{{getLabelDomandaArtigiano("motivo_rifiuto_compagnia")}}</q-item-label>
                                <q-item-label>{{getDomandaArtigianoValue('motivo_rifiuto_compagnia')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_artigiano')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_artigiano')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_artigiano")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_artigiano')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_artigiano")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_artigiano')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_artigiano")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_artigiano')}}</q-item-label>
                                </q-item-section>

                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('multirischio_artigiano')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('multirischio_artigiano')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>

                </fieldset>
            </div>

            <div v-if="isGlobaleFabbricato">
                <fieldset>
                    <legend>Multirischio del Condominio</legend>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_fabbricato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_fabbricato")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFabbricato("anno_ristrutturazione")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('anno_ristrutturazione')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFabbricato("totale_fabbricati")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('totale_fabbricati')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFabbricato("piani_interrati")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('piani_interrati')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFabbricato("piani_fuori_terra")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('piani_fuori_terra')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("negozi_cinema_fabbricato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("negozi_cinema_fabbricato")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFabbricato("negozi_cinema_fabbricato")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('negozi_cinema_fabbricato')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('negozi_cinema_fabbricato').includes('cinema')">
                                <q-item-label caption>{{getLabelDomandaFabbricato("metri_quadri_cinema")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('metri_quadri_cinema')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('negozi_cinema_fabbricato').includes('negozi')">
                                <q-item-label caption>{{getLabelDomandaFabbricato("metri_quadri_negozi")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('metri_quadri_negozi')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('negozi_cinema_fabbricato').includes('negozi')">
                                <q-item-label caption>{{getLabelDomandaFabbricato('attivita_negozi')}}</q-item-label>
                                <q-item-label v-html="getDomandaFabbricatoValue('attivita_negozi').replace('\n','<br>')"></q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFabbricato("indirizzo_condominio")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('indirizzo_condominio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFabbricato("comune_condominio")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('comune_condominio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFabbricato("cap_condominio")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('cap_condominio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFabbricato("provincia_condominio")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('provincia_condominio')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <q-item-label caption>{{getLabelDomandaFabbricato("motivo_rifiuto_compagnia")}}</q-item-label>
                                <q-item-label>{{getDomandaFabbricatoValue('motivo_rifiuto_compagnia')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_fabbricato')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_fabbricato")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_fabbricato')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_fabbricato")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_fabbricato')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_fabbricato")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_fabbricato')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_fabbricato")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_fabbricato')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('globale_fabbricato')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('globale_fabbricato')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isProdottoVirus">
                <fieldset>
                    <legend>Polizza 3-Virus</legend>
                    <br>
                    <legend>Situazione assicurativa</legend>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_globale_fabbricato.motivo_rifiuto_compagnia"
                                    ref="domande_globale_fabbricato.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_virus')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_virus")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('virus')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('virus')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isProdottoTutelaReddito">
                <fieldset>
                    <legend>Protezione del reddito</legend>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("eta_assicurato_reddito") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("eta_assicurato_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipo_lavoro_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipo_lavoro_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiNecessitafromName('tipologia_attivita_persona') === 'DIPENDENTE_PRIVATO'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tempo_indeterminato_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tempo_indeterminato_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiNecessitafromName('tipologia_attivita_persona') === 'DIPENDENTE_PUBBLICO'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tempo_indeterminato_pubblico_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tempo_indeterminato_pubblico_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiNecessitafromName('tipologia_attivita_persona') === 'PROFESSIONISTA'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tempo_partitaiva_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tempo_partitaiva_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiNecessitafromName('tipologia_attivita_persona') === 'IMPRENDITORE'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tempo_partitaiva_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tempo_partitaiva_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiNecessitafromName('tipologia_attivita_persona') === 'AZIENDA'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tempo_partitaiva_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tempo_partitaiva_reddito")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTutelaReddito("descrizione_lavoro_svolto")}}</q-item-label>
                                <q-item-label>{{getDomandaTutelaRedditoValue('descrizione_lavoro_svolto')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("perdita_impiego_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("perdita_impiego_reddito")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <q-item-label caption>{{getLabelDomandaTutelaReddito("motivo_rifiuto_compagnia")}}</q-item-label>
                                <q-item-label>{{getDomandaTutelaRedditoValue('motivo_rifiuto_compagnia')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('tutela_reddito')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('tutela_reddito')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isVeterinaria">
                <fieldset>
                    <legend>Spese Veterinarie</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("totale_animali_veterinaria") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("totale_animali_veterinaria")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("animali_rabbiosio_veterinaria") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("animali_rabbiosio_veterinaria")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>Tipo di Animale</q-item-section>
                            <q-item-section>Nome</q-item-section>
                            <q-item-section>Razza</q-item-section>
                            <q-item-section>Eta` anni</q-item-section>
                            <q-item-section>Num.Microchip</q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("tipo_animale1")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('tipo_animale1')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("nome_animale1")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('nome_animale1')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("razza_animale1")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('razza_animale1')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("eta_animale1")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('eta_animale1')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("microchip_animale1")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('microchip_animale1')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_animali > 1">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("tipo_animale2")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('tipo_animale2')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("nome_animale2")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('nome_animale2')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("razza_animale2")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('razza_animale2')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("eta_animale2")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('eta_animale2')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("microchip_animale2")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('microchip_animale2')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_animali > 2">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("tipo_animale3")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('tipo_animale3')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("nome_animale3")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('nome_animale3')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("razza_animale3")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('razza_animale3')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("eta_animale3")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('eta_animale3')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("microchip_animale3")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('microchip_animale3')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_animali > 3">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("tipo_animale4")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('tipo_animale4')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("nome_animale4")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('nome_animale4')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("razza_animale4")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('razza_animale4')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("eta_animale4")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('eta_animale4')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("microchip_animale4")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('microchip_animale4')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_animali > 4">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("tipo_animale5")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('tipo_animale5')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("nome_animale5")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('nome_animale5')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("razza_animale5")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('razza_animale5')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("eta_animale5")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('eta_animale5')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaVeterinaria("microchip_animale5")}}</q-item-label>
                                <q-item-label>{{getDomandaVeterinariaValue('microchip_animale5')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_veterinaria.motivo_rifiuto_compagnia"
                                    ref="domande_veterinaria.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_veterinaria')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_veterinaria")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_veterinaria')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_veterinario")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_veterinario')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_veterinario")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_veterinario')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_veterinario")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_veterinario')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('spese_veterinarie')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('spese_veterinarie')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isMultirischioAlbergo">
                <fieldset>
                    <legend>Multirischio Albergo</legend>
                    <br>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAlbergo("ubicazione_uguale_sede")}}</q-item-label>
                                <q-item-label>{{getDomandaAlbergoValue('ubicazione_uguale_sede')}}</q-item-label>
                            </q-item-section>

                            <template v-if="getDomandaAlbergoValue('ubicazione_uguale_sede') === 'no'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaAlbergo("indirizzo")}}</q-item-label>
                                    <q-item-label>{{getDomandaAlbergoValue('indirizzo')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaAlbergo("comune_albergo")}}</q-item-label>
                                    <q-item-label>{{getDomandaAlbergoValue('comune_albergo')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaAlbergo("cap_albergo")}}</q-item-label>
                                    <q-item-label>{{getDomandaAlbergoValue('cap_albergo')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaAlbergo("provincia_albergo")}}</q-item-label>
                                    <q-item-label>{{getDomandaAlbergoValue('provincia_albergo')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>

                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAlbergo("denominazione_struttura")}}</q-item-label>
                                <q-item-label>{{getDomandaAlbergoValue('denominazione_struttura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAlbergo("posti_letto")}}</q-item-label>
                                <q-item-label>{{getDomandaAlbergoValue('posti_letto')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAlbergo("valutazione")}}</q-item-label>
                                <q-item-label>{{getDomandaAlbergoValue('valutazione')}}</q-item-label>
                            </q-item-section>

                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("struttura_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("struttura_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("materiale_costruzione_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("materiale_costruzione_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("piani_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("piani_albergo")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAlbergo("superficie_metri_quadri")}}</q-item-label>
                                <q-item-label>{{getDomandaAlbergoValue('superficie_metri_quadri')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAlbergo("fatturato")}}</q-item-label>
                                <q-item-label>{{getDomandaAlbergoValue('fatturato')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_soci_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_soci_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("dipendenti_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("dipendenti_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_dipendenti_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_dipendenti_albergo")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAlbergo("motivo_rifiuto_compagnia")}}</q-item-label>
                                <q-item-label>{{getDomandaAlbergoValue('motivo_rifiuto_compagnia')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_albergo')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_albergo")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_albergo')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_albergo")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_albergo')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_albergo")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_albergo')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_albergo")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_albergo')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('multirischio_albergo')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('multirischio_albergo')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isDeO">
                <fieldset>
                    <legend>Polizza D & O</legend>
                    <br>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("forma_giuridica_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("forma_giuridica_deo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('forma_giuridica_deo') === 'ASSOCIAZIONE'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_associazione_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_associazione_deo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('forma_giuridica_deo') === 'SOCIETA'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_societa_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_societa_deo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("ateco_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("ateco_deo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("attivo_bilancio_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("attivo_bilancio_deo")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaDEO("motivo_rifiuto_compagnia")}}</q-item-label>
                                <q-item-label>{{getDomandaDEOValue('motivo_rifiuto_compagnia')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_deo')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_deo")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('deo')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('deo')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isPolizzaViaggi">
                <fieldset>
                    <legend>Polizza Viaggi</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("attivita_viaggi") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("attivita_viaggi")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('tipologia_viaggio') !== 'SHENGEN'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("area_partenza_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("area_partenza_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('tipologia_viaggio') === 'RILASCIO_RESIDENZA'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("area_partenza_residenza_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("area_partenza_residenza_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('tipologia_viaggio') !== 'SHENGEN'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("paese_destinazione_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("paese_destinazione_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('tipologia_viaggio') === 'SHENGEN'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("paese_destinazione_shengen_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("paese_destinazione_shengen_viaggio")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("data_partenza_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("data_partenza_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("data_rientro_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("data_rientro_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_viaggiatori") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_viaggiatori")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>

                    <fieldset>
                        <legend>Viaggiatore #1</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome1')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome1')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf1')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita1')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita1')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita1')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo1')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune1')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap1')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia1")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia1')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 1">
                        <legend>Viaggiatore #2</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome2')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome2')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf2')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita2')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita2')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita2')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo2')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune2')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap2')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia2")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia2')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 2">
                        <legend>Viaggiatore #3</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome3')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome3')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf3')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita3')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita3')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita3')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo3')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune3')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap3')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia3")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia3')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 3">
                        <legend>Viaggiatore #4</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome4')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome4')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf4')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita4')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita4')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita4')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo4')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune4')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap4')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia4")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia4')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 4">
                        <legend>Viaggiatore #5</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome5')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome5')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf5')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita5')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita5')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita5')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo5')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune5')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap5')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia5")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia5')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 5">
                        <legend>Viaggiatore #6</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome6')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome6')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf6')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita6')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita6')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita6')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo6')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune6')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap6')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia6")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia6')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 6">
                        <legend>Viaggiatore #7</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome7')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome7')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf7')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita7')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita7')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita7')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo7')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune7')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap7')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia7")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia7')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 7">
                        <legend>Viaggiatore #8</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome8')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome8')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf8')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita8')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita8')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita8')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo8')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune8')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap8')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia8")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia8')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 8">
                        <legend>Viaggiatore #9</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome9')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome9')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf9')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita9')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita9')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita9')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo9')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune9')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap9')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia9")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia9')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 9">
                        <legend>Viaggiatore #10</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cognome10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cognome10')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("nome10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('nome10')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cf10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cf10')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("data_nascita10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('data_nascita10')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune_nascita10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune_nascita10')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia_nascita10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia_nascita10')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("indirizzo10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('indirizzo10')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("comune10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('comune10')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("cap10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('cap10')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaViaggi("provincia10")}}</q-item-label>
                                    <q-item-label>{{getDomandaViaggiValue('provincia10')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('viaggi')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('viaggi')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isPolizzaLeasing">
                <fieldset>
                    <legend>Polizza Leasing</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("durata_leasing") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("durata_leasing")) }} Anni</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("valore_beni_leasing") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("valore_beni_leasing")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaLeasing("descrizione_beni_leasing")}}</q-item-label>
                                <q-item-label>{{getDomandaLeasingValue('descrizione_beni_leasing')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaLeasing("descrizione_attivita_leasing")}}</q-item-label>
                                <q-item-label>{{getDomandaLeasingValue('descrizione_attivita_leasing')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaLeasing("ubicazione_uguale_sede")}}</q-item-label>
                                <q-item-label>{{getDomandaLeasingValue('ubicazione_uguale_sede')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_leasing.ubicazione_uguale_sede.value == 'no'">
                                <q-item-label caption>{{getLabelDomandaLeasing("indirizzo")}}</q-item-label>
                                <q-item-label>{{getDomandaLeasingValue('indirizzo')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_leasing.ubicazione_uguale_sede.value == 'no'">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaLeasing("comune_leasing")}}</q-item-label>
                                <q-item-label>{{getDomandaLeasingValue('comune_leasing')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaLeasing("cap_leasing")}}</q-item-label>
                                <q-item-label>{{getDomandaLeasingValue('cap_leasing')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaLeasing("provincia_leasing")}}</q-item-label>
                                <q-item-label>{{getDomandaLeasingValue('provincia_leasing')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <q-item-label caption>{{getLabelDomandaLeasing("motivo_rifiuto_compagnia")}}</q-item-label>
                                <q-item-label>{{getDomandaLeasingValue('motivo_rifiuto_compagnia')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_leasing')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_leasing")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_leasing')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_leasing")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_leasing')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_leasing")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_leasing')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_leasing")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_leasing')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('leasing')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('leasing')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isPatrimoniale">
                <fieldset>
                    <legend>R.C. Patrimoniale</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_cariche_patrimoniale") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_cariche_patrimoniale")) }}</q-item-label>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaPatrimoniale("contraente_uguale_assicurato")}}</q-item-label>
                                <q-item-label>{{getDomandaPatrimonialeValue('contraente_uguale_assicurato')}}</q-item-label>
                            </q-item-section>
                        </q-item>

                        <div v-if="domande_patrimoniale.contraente_uguale_assicurato.value === 'no'">
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("cognome")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('cognome')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("nome")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('nome')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("codice_fiscale")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('codice_fiscale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("sesso")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('sesso')}}</q-item-label>
                                </q-item-section>

                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("stato_nascita_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('stato_nascita_patrimoniale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("data_nascita")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('data_nascita')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="domande_patrimoniale.stato_nascita_patrimoniale.value === 'ITALIA'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("comune_nascita_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('comune_nascita_patrimoniale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("cap_nascita_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('cap_nascita_patrimoniale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("provincia_nascita_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('provincia_nascita_patrimoniale')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("indirizzo_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('indirizzo_patrimoniale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("comune_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('comune_patrimoniale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("cap_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('cap_patrimoniale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("provincia_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('provincia_patrimoniale')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </div>
                    </q-list>
                    <br>
                    <fieldset>
                        <legend>Carica #1</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') == 1">
                                    <q-item-label caption>{{ getLabelAnalisiRischioFromName("carica_svolta_patrimoniale") }} </q-item-label>
                                    <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("carica_svolta_patrimoniale")) }}</q-item-label>
                                </q-item-section>
                                <q-item-section v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 1">
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta1")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta1')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza1")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza1')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza1")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza1')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 1">
                        <legend>Carica #2</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta2")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta2')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza2")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza2')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza2")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza2')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 2">
                        <legend>Carica #3</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta3")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta3')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza3")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza3')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza3")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza3')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 3">
                        <legend>Carica #4</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta4")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta4')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza4")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza4')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza4")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza4')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 4">
                        <legend>Carica #5</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta5")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta5')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza5")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza5')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza5")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza5')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 5">
                        <legend>Carica #6</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta6")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta6')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza6")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza6')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza6")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza6')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 6">
                        <legend>Carica #7</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta7")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta7')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza7")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza7')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza7")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza7')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 7">
                        <legend>Carica #8</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta8")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta8')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza8")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza8')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza8")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza8')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 8">
                        <legend>Carica #9</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta9")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta9')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza9")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza9')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza9")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza9')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 9">
                        <legend>Carica #10</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("carica_svolta10")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('carica_svolta10')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("ente_appartenenza10")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('ente_appartenenza10')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaPatrimoniale("citta_ente_appartenenza10")}}</q-item-label>
                                    <q-item-label>{{getDomandaPatrimonialeValue('citta_ente_appartenenza10')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <q-item-label caption>{{getLabelDomandaPatrimoniale("motivo_rifiuto_compagnia")}}</q-item-label>
                                <q-item-label>{{getDomandaPatrimonialeValue('motivo_rifiuto_compagnia')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_patrimoniale')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_patrimoniale")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_patrimoniale')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_patrimoniale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_patrimoniale')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_patrimoniale")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_patrimoniale')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('patrimoniale')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('patrimoniale')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isMultirischioUfficio">
                <fieldset>
                    <legend>Multirischio Ufficio</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("attivita_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("attivita_ufficio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("fatturato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("superfice_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("superfice_ufficio")) }} m.q.</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_ufficio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("piani_fabbricato_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("piani_fabbricato_ufficio")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("materiale_costruzione_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("materiale_costruzione_ufficio")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_soci_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_soci_ufficio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("dipendenti_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("dipendenti_ufficio")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('dipendenti_ufficio') == 'SI'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_dipendenti_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_dipendenti_ufficio")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaUfficio("ubicazione_diverso_sede")}}</q-item-label>
                                <q-item-label>{{getDomandaUfficioValue('ubicazione_diverso_sede')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section v-if="domande_multirischio_ufficio.ubicazione_diverso_sede.value == 'si'">
                                <q-item-label caption>{{getLabelDomandaUfficio("indirizzo_ufficio")}}</q-item-label>
                                <q-item-label>{{getDomandaUfficioValue('indirizzo_ufficio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_multirischio_ufficio.ubicazione_diverso_sede.value == 'si'">
                                <q-item-label caption>{{getLabelDomandaUfficio("comune_ufficio")}}</q-item-label>
                                <q-item-label>{{getDomandaUfficioValue('comune_ufficio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_multirischio_ufficio.ubicazione_diverso_sede.value == 'si'">
                                <q-item-label caption>{{getLabelDomandaUfficio("cap_ufficio")}}</q-item-label>
                                <q-item-label>{{getDomandaUfficioValue('cap_ufficio')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("provincia_fabbricato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("provincia_fabbricato")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <q-item-label caption>{{getLabelDomandaUfficio("motivo_rifiuto_compagnia")}}</q-item-label>
                                <q-item-label>{{getDomandaUfficioValue('motivo_rifiuto_compagnia')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_ufficio')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_ufficio")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_ufficio')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_ufficio")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_ufficio')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_ufficio")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_ufficio')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_ufficio")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_ufficio')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('multirischio_ufficio')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('multirischio_ufficio')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isRCEdilizia">
                <fieldset>
                    <legend>RC Impresa Edile</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("attivita_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("attivita_rc_edile")) }} Anni</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("fatturato") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_soci_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_soci_rc_edile")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("dipendenti_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("dipendenti_rc_edile")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('dipendenti_rc_edile') == 'SI'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("numero_dipendenti_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("numero_dipendenti_rc_edile")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("perc_lavori_subappalto_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("perc_lavori_subappalto_rc_edile")) }} %</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaRDEdilizia("motivo_rifiuto_compagnia")}}</q-item-label>
                                    <q-item-label>{{getDomandaRCEdiliziaValue('motivo_rifiuto_compagnia')}}</q-item-label>
                                </q-item-section>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_rc_edile')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_rc_edile")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('sinistri_rc_edile')) === 'Si'">
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("data_sinistro_edilizia")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('data_sinistro_edilizia')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("descrizione_evento_edilizia")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('descrizione_evento_edilizia')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaArtigiano("importo_liquidato_edilizia")}}</q-item-label>
                                    <q-item-label>{{getDomandaArtigianoValue('importo_liquidato_edilizia')}}</q-item-label>
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('rc_impresa_edile')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('rc_impresa_edile')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isTCM">
                <fieldset>
                    <legend>Temporanea caso morte (TCM)</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("eta_tcm") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("eta_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("fumatore_tcm") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("fumatore_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label v-html="getLabelAnalisiRischioFromName('durata_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("durata_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label v-html="getLabelAnalisiRischioFromName('capitale_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("capitale_tcm")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("contraente_uguale_assicurato")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('contraente_uguale_assicurato')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("altezza_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('altezza_tcm')}} cm</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("peso_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('peso_tcm')}} Kg</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_tcm.contraente_uguale_assicurato.value == 'no'">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("cognome")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('cognome')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("nome")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('nome')}}</q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item v-if="domande_tcm.contraente_uguale_assicurato.value == 'no'">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("data_nascita")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('data_nascita')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("codice_fiscale")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('codice_fiscale')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("sesso")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('sesso')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_tcm.contraente_uguale_assicurato.value == 'no'">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("stato_nascita_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('stato_nascita_tcm')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_tcm.stato_nascita_tcm.value == 'ITALIA'">
                                <q-item-label caption>{{getLabelDomandaTcm("comune_nascita_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('comune_nascita_tcm')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_tcm.stato_nascita_tcm.value == 'ITALIA'">
                                <q-item-label caption>{{getLabelDomandaTcm("cap_nascita_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('cap_nascita_tcm')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_tcm.stato_nascita_tcm.value == 'ITALIA'">
                                <q-item-label caption>{{getLabelDomandaTcm("provincia_nascita_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('provincia_nascita_tcm')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_tcm.contraente_uguale_assicurato.value == 'no'">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("indirizzo_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('indirizzo_tcm')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("comune_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('comune_tcm')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("cap_tcm")}}</q-item-label>
                                <q-item-label>{{ getDomandaTcmValue('cap_tcm') }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("provincia_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('provincia_tcm')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label v-html="getLabelAnalisiRischioFromName('attivita_rischio_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("attivita_rischio_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('attivita_rischio_tcm') == 'NO'">
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("attivita_tcm") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("attivita_tcm")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <legend>QUESTIONARIO ANAMNESTICO “A”</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label v-html="getLabelAnalisiRischioFromName('interrompe_lavoro_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("interrompe_lavoro_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label v-html="getLabelAnalisiRischioFromName('ricoveri_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("ricoveri_tcm")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label v-html="getLabelAnalisiRischioFromName('malattie_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("malattie_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label v-html="getLabelAnalisiRischioFromName('patologie_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("patologie_tcm")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label v-html="getLabelAnalisiRischioFromName('altro_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("altro_tcm")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <template v-if="isQuestionarioBTcmAbilitato">
                        <legend>QUESTIONARIO ANAMNESTICO “B”</legend>

                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaTcm("attesa_ricovero_tcm")}}</q-item-label>
                                    <q-item-label>{{getDomandaTcmValue('attesa_ricovero_tcm')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaTcm("esami_diagnostici_tcm")}}</q-item-label>
                                    <q-item-label>{{getDomandaTcmValue('esami_diagnostici_tcm')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaTcm("malattie_terapia_medica_tcm")}}</q-item-label>
                                    <q-item-label>{{getDomandaTcmValue('malattie_terapia_medica_tcm')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaTcm("attivita_sportive_tcm")}}</q-item-label>
                                    <q-item-label>{{getDomandaTcmValue('attivita_sportive_tcm')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaTcm("sostanze_stupefacenti_tcm")}}</q-item-label>
                                    <q-item-label>{{getDomandaTcmValue('sostanze_stupefacenti_tcm')}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaTcm("inabile_tcm")}}</q-item-label>
                                    <q-item-label>{{getDomandaTcmValue('inabile_tcm')}}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </template>

                    <legend>DESIGNAZIONE DEI BENEFICIARI</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaTcm("tipo_beneficiario_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('tipo_beneficiario_tcm')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                <q-item-label caption>{{getLabelDomandaTcm("totale_beneficiari_tcm")}}</q-item-label>
                                <q-item-label>{{getDomandaTcmValue('totale_beneficiari_tcm')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                            <q-item-section>
                                <q-list bordered>
                                    <q-item><q-item-section>Inserisci i dati dei Beneficiari</q-item-section></q-item>
                                    <q-item>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("cognome_nome1_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('cognome_nome1_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("data_nascita1_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('data_nascita1_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("luogo_nascita1_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('luogo_nascita1_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("residenza1_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('residenza1_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("relazione1_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('relazione1_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("percentuale_ripartizione1_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('percentuale_ripartizione1_tcm')}}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="parseInt(domande_tcm.totale_beneficiari_tcm.value) > 1">
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("cognome_nome2_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('cognome_nome2_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("data_nascita2_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('data_nascita2_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("luogo_nascita2_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('luogo_nascita2_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("residenza2_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('residenza2_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("relazione2_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('relazione2_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("percentuale_ripartizione2_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('percentuale_ripartizione2_tcm')}}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="parseInt(domande_tcm.totale_beneficiari_tcm.value) > 2">
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("cognome_nome3_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('cognome_nome3_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("data_nascita3_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('data_nascita3_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("luogo_nascita3_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('luogo_nascita3_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("residenza3_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('residenza3_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("relazione3_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('relazione3_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("percentuale_ripartizione3_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('percentuale_ripartizione3_tcm')}}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="parseInt(domande_tcm.totale_beneficiari_tcm.value) > 3">
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("cognome_nome4_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('cognome_nome4_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("data_nascita4_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('data_nascita4_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("luogo_nascita4_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('luogo_nascita4_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("residenza4_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('residenza4_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("relazione4_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('relazione4_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("percentuale_ripartizione4_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('percentuale_ripartizione4_tcm')}}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="parseInt(domande_tcm.totale_beneficiari_tcm.value) > 4">
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("cognome_nome5_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('cognome_nome5_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("data_nascita5_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('data_nascita5_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("luogo_nascita5_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('luogo_nascita5_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("residenza5_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('residenza5_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("relazione5_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('relazione5_tcm')}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                            <q-item-label caption>{{getLabelDomandaTcm("percentuale_ripartizione5_tcm")}}</q-item-label>
                                            <q-item-label>{{getDomandaTcmValue('percentuale_ripartizione5_tcm')}}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-item-section>
                        </q-item>
                        <br>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isTutelaLegaleMedici">
                <fieldset>
                    <legend>Tutela legale settore Medico</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("specializzazione_tl_medici") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("specializzazione_tl_medici")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'"
                                    :domanda="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    ref="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_tutela_medico')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_tutela_medico")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('tutela_legale_medici')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('tutela_legale_medici')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isTutelaLegaleTecnici">
                <fieldset>
                    <legend>Tutela legale settore Tecnico</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("fatturato") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_schifata')) === 'Si'"
                                    :domanda="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    ref="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_tutela_tecnico')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_tutela_tecnico")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <br>
                    <legend> <strong>Caratteristiche proposta</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('tutela_legale_professionisti')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('tutela_legale_professionisti')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isCauzioni">
                <fieldset>
                    <legend>Cauzioni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipo_rischio_cauzioni") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipo_rischio_cauzioni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isAsseverazioni">
                <fieldset>
                    <legend>Asseverazioni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipo_rischio_asseverazioni") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipo_rischio_asseverazioni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isFideiussioni">
                <fieldset>
                    <legend>Fideiussioni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipo_rischio_fidejussione") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipo_rischio_fidejussione")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isFotovoltaico">
                <fieldset>
                    <legend>All Risk Fotovoltaico</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costo_impianto_fotovoltaico") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costo_impianto_fotovoltaico")) }} Euro</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("estensioni_fotovoltaico") }} </q-item-label>
                                <q-item-label>
                                    <ul>
                                        <li v-for="(estensione,index) in estensioni_fotovoltaico" v-bind:key="index">
                                            {{estensione.label}}
                                        </li>
                                    </ul>

                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item>
                            <q-item-section>
                                <q-item-section>
                                    <q-item-label caption>{{getLabelDomandaFotovoltaico("indirizzo_fotovoltaico")}}</q-item-label>
                                    <q-item-label>{{getDomandaFotovoltaicoValue('indirizzo_fotovoltaico')}}</q-item-label>
                                </q-item-section>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("comune_fotovoltaico")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoValue('comune_fotovoltaico')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("cap_fotovoltaico")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoValue('cap_fotovoltaico')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("provincia_fotovoltaico")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoValue('provincia_fotovoltaico')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("tipologia_fotovoltaico")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoLabel('tipologia_fotovoltaico')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("tipo_installazione")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoLabel('tipo_installazione')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_fotovoltaico.tipo_installazione.value === 'tetto'">
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("altezza_impianto")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoLabel('altezza_impianto')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section v-if="domande_fotovoltaico.tipo_installazione.value === 'tetto'">
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("numero_piani")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoLabel('numero_piani')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("numero_inverter")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoLabel('numero_inverter')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("numero_pannelli1")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoLabel('numero_pannelli1')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_fotovoltaico.numero_inverter.value >= 2">
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("numero_pannelli2")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoLabel('numero_pannelli2')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_fotovoltaico.numero_inverter.value >= 3">
                                <q-item-label caption>{{getLabelDomandaFotovoltaico("numero_pannelli3")}}</q-item-label>
                                <q-item-label>{{getDomandaFotovoltaicoLabel('numero_pannelli3')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaAnalisiRischioFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_fotovoltaico')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_fotovoltaico")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isDonazioni">
                <fieldset>
                    <legend>Donazioni Immobili</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_donazione") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_donazione")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("valore_donazione") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("valore_donazione")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
                <br><br>
            </div>

            <div v-if="isDroni">
                <fieldset>
                    <legend>SARP Droni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("totale_droni") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("totale_droni")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_1")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_1")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_1")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_1")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_1")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 2">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_2")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_2")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_2")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_2")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_2")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 3">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_3")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_3")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_3")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_3")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_3")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 4">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_4")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_4")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_4")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_4")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_4")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 5">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_5")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_5")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_5")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_5")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_5")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 6">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_6")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_6")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_6")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_6")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_6")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 7">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_7")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_7")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_7")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_7")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_7")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 8">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_8")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_8")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_8")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_8")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_8")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 9">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_9")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_9")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_9")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_9")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_9")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni === 10">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("costruttore_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("costruttore_drone_10")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("modello_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("modello_drone_10")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("anno_costruzione_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("anno_costruzione_drone_10")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_drone_10")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("peso_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("peso_drone_10")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("sinistri_droni") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_droni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
                <br><br>
            </div>

            <div v-if="isCatastrofali">
                <fieldset>
                    <legend>Rischi Catastrofali</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_fabbricato_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_fabbricato_catastrofali")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipo_dimora_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipo_dimora_catastrofali")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipo_fabbricato_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipo_fabbricato_catastrofali")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipo_abitazione_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipo_abitazione_catastrofali")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("materiale_costruzione_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("materiale_costruzione_catastrofali")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("metri_quadri_catastrofale") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("metri_quadri_catastrofale")) }} mq</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCatastrofali("piani_interrati")}}</q-item-label>
                                <q-item-label>{{getDomandaCatastrofaliValue('piani_interrati')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCatastrofali("piani_fuori_terra")}}</q-item-label>
                                <q-item-label>{{getDomandaCatastrofaliValue('piani_fuori_terra')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCatastrofali("indirizzo_catastrofali")}}</q-item-label>
                                <q-item-label>{{getDomandaCatastrofaliValue('indirizzo_catastrofali')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCatastrofali("comune_catastrofali")}}</q-item-label>
                                <q-item-label>{{getDomandaCatastrofaliValue('comune_catastrofali')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaCatastrofali("cap_catastrofali")}}</q-item-label>
                                <q-item-label>{{getDomandaCatastrofaliValue('cap_catastrofali')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("provincia_catastrofali") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("provincia_catastrofali")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>

                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-label caption>{{ getLabelAnalisiRischioFromName("sinistri_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_catastrofali")) }} mq</q-item-label>
                        </q-item>
                    </q-list>
                </fieldset>
                <br><br>
            </div>

            <div v-if="isStruttureSanitarie">
                <fieldset>
                    <legend>Strutture Sanitarie</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_struttura_sanitaria") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_struttura_sanitaria")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("struttura_sanitaria_convenzionata") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("struttura_sanitaria_convenzionata")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("fatturato") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaStruttureSanitarie("data_apertura_sanitarie")}}</q-item-label>
                                <q-item-label>{{getDomandaStruttureSanitarieValue('data_apertura_sanitarie')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaStruttureSanitarie("ubicazione_uguale_sede_sanitarie")}}</q-item-label>
                                <q-item-label>{{getDomandaStruttureSanitarieValue('ubicazione_uguale_sede_sanitarie')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_strutture_sanitarie.ubicazione_uguale_sede_sanitarie.value === 'no'">
                                <q-item-label caption>{{getLabelDomandaStruttureSanitarie("indirizzo_ubicazione_sanitarie")}}</q-item-label>
                                <q-item-label>{{getDomandaStruttureSanitarieValue('indirizzo_ubicazione_sanitarie')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_strutture_sanitarie.ubicazione_uguale_sede_sanitarie.value === 'no'">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaStruttureSanitarie("comune_ubicazione_sanitarie")}}</q-item-label>
                                <q-item-label>{{getDomandaStruttureSanitarieValue('comune_ubicazione_sanitarie')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaStruttureSanitarie("cap_ubicazione_sanitarie")}}</q-item-label>
                                <q-item-label>{{getDomandaStruttureSanitarieValue('cap_ubicazione_sanitarie')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaStruttureSanitarie("provincia_ubicazione_sanitarie")}}</q-item-label>
                                <q-item-label>{{getDomandaStruttureSanitarieValue('provincia_ubicazione_sanitarie')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche generali</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('strutture_sanitarie')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('strutture_sanitarie')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption >{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                       <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_in_corso") }} </q-item-label>
                            </q-item-section>
                            <div v-show="getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ">
                                <q-item >
                                    <q-item-section>
                                        <q-item-label caption>Impresa di assicurazione</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('compagnia')}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption>Data di scadenza della copertura</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('scadenza_copertura')}}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_struttura_sanitaria')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_struttura_sanitaria")) }} </q-item-label>
                            </q-item-section>
                        </q-item>

                    </q-list>
                </fieldset>
            </div>

            <div v-if="isShengen">
                <fieldset>
                    <legend>Cauzioni Shengen</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_shengen")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("paese_partenza_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("paese_partenza_shengen")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("data_partenza_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("data_partenza_shengen")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("paese_destinazione_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("paese_destinazione_shengen")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("data_rientro_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("data_rientro_shengen")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Informazioni sulla persona ospitata</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaShengen("cognome_shengen")}}</q-item-label>
                                <q-item-label>{{getDomandaShengenValue('cognome_shengen')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaShengen("nome_shengen")}}</q-item-label>
                                <q-item-label>{{getDomandaShengenValue('nome_shengen')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaShengen("data_nascita_shengen")}}</q-item-label>
                                <q-item-label>{{getDomandaShengenValue('data_nascita_shengen')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaShengen("numero_passaporto_shengen")}}</q-item-label>
                                <q-item-label>{{getDomandaShengenValue('numero_passaporto_shengen')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche generali</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('shengen')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('shengen')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
                <br>
            </div>

            <div v-if="isAssociazioni">
                <fieldset>
                    <legend>Associazioni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("segmento_associazioni") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("segmento_associazioni")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("servizi_svolti_associazioni") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("servizi_svolti_associazioni")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipologia_ente_associazioni") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipologia_ente_associazioni")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaShengen("numero_associati_associazioni")}}</q-item-label>
                                <q-item-label>{{getDomandaShengenValue('numero_associati_associazioni')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaShengen("numero_volontari_associazioni")}}</q-item-label>
                                <q-item-label>{{getDomandaShengenValue('numero_volontari_associazioni')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaShengen("numero_dipendenti_associazioni")}}</q-item-label>
                                <q-item-label>{{getDomandaShengenValue('numero_dipendenti_associazioni')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>

                    <legend> <strong>Caratteristiche generali</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('associazioni')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('associazioni')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                       <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_in_corso") }} </q-item-label>
                            </q-item-section>
                            <div v-show="getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ">
                                <q-item >
                                    <q-item-section>
                                        <q-item-label caption>Impresa di assicurazione</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('compagnia')}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption>Data di scadenza della copertura</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('scadenza_copertura')}}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_struttura_associazioni')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_struttura_associazioni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isMultirischioAgricoltura">
                <fieldset>
                    <legend>Multirischio Agricoltura</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("fatturato") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("tipo_fabbricato_agricoltura") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("tipo_fabbricato_agricoltura")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("materiale_fabbricato_agricoltura") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("materiale_fabbricato_agricoltura")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("tipologia_contraente_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('tipologia_contraente_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("valutazione_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('valutazione_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("allarme_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('allarme_agricoltura')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("numero_post_letto_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('numero_post_letto_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("anno_costruzione_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('anno_costruzione_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("superficie_mq_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('superficie_mq_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("superficie_ettari_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('superficie_ettari_agricoltura')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("stabilmente_abitata_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('stabilmente_abitata_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("numero_soci_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('numero_soci_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("numero_dipendenti_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('numero_dipendenti_agricoltura')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("ubicazione_uguale_sede_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('ubicazione_uguale_sede_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domande_multirischio_agricoltura.ubicazione_uguale_sede_agricoltura.value === 'no'">
                                <q-item-label caption>{{getLabelDomandaAgricoltura("indirizzo_ubicazione_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('indirizzo_ubicazione_agricoltura')}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_multirischio_agricoltura.ubicazione_uguale_sede_agricoltura.value === 'no'">
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("comune_ubicazione_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('comune_ubicazione_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{getLabelDomandaAgricoltura("cap_ubicazione_agricoltura")}}</q-item-label>
                                <q-item-label>{{getDomandaAgricolturaValue('cap_ubicazione_agricoltura')}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelAnalisiRischioFromName("provincia_agricoltura") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("provincia_agricoltura")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche generali</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Compagnia</q-item-label>
                                <q-item-label>
                                    {{getCompagniaSelezionata('multirischio_agricoltura')}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive('multirischio_agricoltura')" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption>{{garanzia.label}}</q-item-label>
                                <q-item-label>
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                       <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_in_corso") }} </q-item-label>
                            </q-item-section>
                            <div v-show="getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ">
                                <q-item >
                                    <q-item-section>
                                        <q-item-label caption>Impresa di assicurazione</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('compagnia')}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption>Data di scadenza della copertura</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('scadenza_copertura')}}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelAnalisiRischioFromName('sinistri_struttura_agricoltura')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaAnalisiRischioFromName("sinistri_struttura_agricoltura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>

                    </q-list>
                </fieldset>
            </div>

        </div>
    </div>
</template>

<script>
    //import QQButton from "@/components/QQButton.vue";
    import { mapState,mapActions } from "vuex";
    import { mapFields} from "vuex-map-fields";
    import commonLib from "../../libs/commonLib";
    //import DomandaQuestionarioAssuntivo from "@/components/QuestionariAssuntivi/DomandaQuestionarioAssuntivo";

    export default {
        name: "QQVisualizzaQuestionarioPersoneAziende",
        components: {
            //Domanda: DomandaQuestionarioAssuntivo
        },
        data() {
            return {
                domande_caricate: false,
                elenco_errori: [],
                totale_errori: 0,
                dati_cliente: {}
            }
        },
        props: {
            indice_questionario_rischio: {
                type: Number,
                required: false,
                default: 0
            }
        },
        methods: {
            ...mapActions({
                loadQuestionariAssuntivi: "gestioneQuotazioni/loadQuestionariAssuntivi",
                fetchDatiCliente: "gestioneClienti/fetchDatiCliente"
            }),
            getRispostaAnalisiNecessitafromName(name) {
                if (!commonLib.isEmpty(this.analisi_necessita)) {
                    var domanda = this.analisi_necessita.find(d => {
                        return d.name === name;
                    });

                    // Se la domanda non e` presente nel questionario allora domanda=undefined
                    if (commonLib.isEmpty(domanda)) return "";

                    return domanda.value.toUpperCase();
                }

                return "";
            },
            getDatiCliente(index) {
                if (commonLib.isEmpty(this.dati_cliente)) return "";

                return this.dati_cliente[index];
            },
            getDatiResidenzaCliente(campo) {
                if(commonLib.isEmpty(this.dati_cliente)) return "";

                let value = this.dati_cliente.altri_dati[0].indirizzi.filter(v => {
                    return v.tipo === "residenza";
                });

                return value[0][campo];
            },
            get_prodotto_id() {
                if (this.isMultirischioCasa) return "multirischio_casa";

            },
            getLabelAnalisiRischioFromName(name) {
                var obj = this.getDomandaAnalisiRischioFromName(name);
                if (commonLib.isEmpty(obj)) return "Campo non trovato: "+name;
                return obj.label;
            },
            getRispostaAnalisiRischioFromName(name) {
                //console.log("name:",name);
                if (commonLib.isEmpty(this.analisi_rischio)) return "";

                let indice = this.getIndiceQuestionarioRischio;
                //console.log("Indice questionario rischio:",indice);

                let analisi_rischio_rc = this.analisi_rischio[indice];
               // console.log("indice questionario:",this.indice_questionario_rischio)

                if (!commonLib.isEmpty(analisi_rischio_rc)) {
                    var domanda = analisi_rischio_rc.domande.find(d => {
                        return d.name === name;
                    });

                    if (commonLib.isEmpty(domanda)) return "";

                    var risposta =  domanda.value;

                    if (!Array.isArray(risposta))
                        return risposta.toUpperCase();

                    return risposta;
                }

                return "";

            },
            getDomandaAnalisiRischioFromName(name) {
                // analisi rischio RC sempre in posizione 0
                // TODO: andare per id

                // non c'è analisi rischio
                if (commonLib.isEmpty(this.analisi_rischio)) return null;

                let indice = this.getIndiceQuestionarioRischio;
                let analisi_rischio_rc = this.analisi_rischio[indice];
                if (commonLib.isEmpty(analisi_rischio_rc)) {
                    return null;
                }

                // cerca la domanda
                var domanda = analisi_rischio_rc.domande.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },
            analizzaRisposta(garanzia) {
                // type,value,options

                if (commonLib.isEmpty(garanzia)) return [];

                let result = "";
                let value = garanzia.value;
                let options = garanzia.options;
                let type = garanzia.type;

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                });

                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        if (type === "checkbox") return garanzia.is_checked ? "SI" : "NO";

                        return value.replace("_"," ");
                    } else
                        return "";
                }
            },
            getLabelDomandaInfortuni(domanda) {
                if (this.isInfortuni) {
                    if (commonLib.isEmpty(this.domande_infortuni[domanda])) return "";

                    return this.domande_infortuni[domanda].label;
                }

                return "";
            },
            getDomandaInfortuniValue(domanda) {
                if (this.isInfortuni) {
                    if (commonLib.isEmpty(this.domande_infortuni[domanda])) return "";

                    return this.domande_infortuni[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaCommercio(domanda) {
                if (this.isMultirischioCommercio) {
                    if (commonLib.isEmpty(this.domande_multirischio_commercio[domanda])) return "";

                    return this.domande_multirischio_commercio[domanda].label;
                }

                return "";
            },
            getDomandaCommercioValue(domanda) {
                if (this.isMultirischioCommercio) {
                    if (commonLib.isEmpty(this.domande_multirischio_commercio[domanda])) return "";

                    return this.domande_multirischio_commercio[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaArtigiano(domanda) {
                if (this.isMultirischioArtigiano) {
                    if (commonLib.isEmpty(this.domande_multirischio_artigiano[domanda])) return "";

                    return this.domande_multirischio_artigiano[domanda].label;
                }

                return "";
            },
            getDomandaArtigianoValue(domanda) {
                if (this.isMultirischioArtigiano) {
                    if (commonLib.isEmpty(this.domande_multirischio_artigiano[domanda])) return "";

                    return this.domande_multirischio_artigiano[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaFabbricato(domanda) {
                if (this.isGlobaleFabbricato) {
                    if (commonLib.isEmpty(this.domande_globale_fabbricato[domanda])) return "";

                    return this.domande_globale_fabbricato[domanda].label;
                }

                return "";
            },
            getDomandaFabbricatoValue(domanda) {
                if (this.isGlobaleFabbricato) {
                    if (commonLib.isEmpty(this.domande_globale_fabbricato[domanda])) return "";

                    return this.domande_globale_fabbricato[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaTutelaReddito(domanda) {
                if (this.isProdottoTutelaReddito) {
                    if (commonLib.isEmpty(this.domande_tutela_reddito[domanda])) return "";

                    return this.domande_tutela_reddito[domanda].label;
                }

                return "";
            },
            getDomandaTutelaRedditoValue(domanda) {
                if (this.isProdottoTutelaReddito) {
                    if (commonLib.isEmpty(this.domande_tutela_reddito[domanda])) return "";

                    return this.domande_tutela_reddito[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaVeterinaria(domanda) {
                if (this.isVeterinaria) {
                    if (commonLib.isEmpty(this.domande_veterinaria[domanda])) return "";

                    return this.domande_veterinaria[domanda].label;
                }

                return "";
            },
            getDomandaVeterinariaValue(domanda) {
                if (this.isVeterinaria) {
                    if (commonLib.isEmpty(this.domande_veterinaria[domanda])) return "";

                    return this.domande_veterinaria[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaAlbergo(domanda) {
                if (this.isMultirischioAlbergo) {
                    if (commonLib.isEmpty(this.domande_multirischio_albergo[domanda])) return "";

                    return this.domande_multirischio_albergo[domanda].label;
                }

                return "";
            },
            getDomandaAlbergoValue(domanda) {
                if (this.isMultirischioAlbergo) {
                    if (commonLib.isEmpty(this.domande_multirischio_albergo[domanda])) return "";

                    return this.domande_multirischio_albergo[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaViaggi(domanda) {
                if (this.isPolizzaViaggi) {
                    if (commonLib.isEmpty(this.domande_viaggi[domanda])) return "";

                    return this.domande_viaggi[domanda].label;
                }

                return "";
            },
            getDomandaViaggiValue(domanda) {
                if (this.isPolizzaViaggi) {
                    if (commonLib.isEmpty(this.domande_viaggi[domanda])) return "";

                    return this.domande_viaggi[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaLeasing(domanda) {
                if (this.isPolizzaLeasing) {
                    if (commonLib.isEmpty(this.domande_leasing[domanda])) return "";

                    return this.domande_leasing[domanda].label;
                }

                return "";
            },
            getDomandaLeasingValue(domanda) {
                if (this.isPolizzaLeasing) {
                    if (commonLib.isEmpty(this.domande_leasing[domanda])) return "";

                    return this.domande_leasing[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaPatrimoniale(domanda) {
                if (this.isPatrimoniale) {
                    if (commonLib.isEmpty(this.domande_patrimoniale[domanda])) return "";

                    return this.domande_patrimoniale[domanda].label;
                }

                return "";
            },
            getDomandaPatrimonialeValue(domanda) {
                if (this.isPatrimoniale) {
                    if (commonLib.isEmpty(this.domande_patrimoniale[domanda])) return "";

                    return this.domande_patrimoniale[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaUfficio(domanda) {
                if (this.isMultirischioUfficio) {
                    if (commonLib.isEmpty(this.domande_multirischio_ufficio[domanda])) return "";

                    return this.domande_multirischio_ufficio[domanda].label;
                }

                return "";
            },
            getDomandaUfficioValue(domanda) {
                if (this.isMultirischioUfficio) {
                    if (commonLib.isEmpty(this.domande_multirischio_ufficio[domanda])) return "";

                    return this.domande_multirischio_ufficio[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaRCEdilizia(domanda) {
                if (this.isRCEdilizia) {
                    if (commonLib.isEmpty(this.domande_rc_edilizia[domanda])) return "";

                    return this.domande_rc_edilizia[domanda].label;
                }

                return "";
            },
            getDomandaRCEdiliziaValue(domanda) {
                if (this.isRCEdilizia) {
                    if (commonLib.isEmpty(this.domande_rc_edilizia[domanda])) return "";

                    return this.domande_rc_edilizia[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaTcm(domanda) {
                if (this.isTCM) {
                    if (commonLib.isEmpty(this.domande_tcm[domanda])) return "";

                    return this.domande_tcm[domanda].label;
                }

                return "";
            },
            getDomandaTcmValue(domanda) {
                if (this.isTCM) {
                    if (commonLib.isEmpty(this.domande_tcm[domanda])) return "";

                    var risposta = this.analizzaRisposta(this.domande_tcm[domanda]);

                    console.log("risposta:",risposta);

                    // return this.domande_tcm[domanda].value.toUpperCase();
                    return risposta.toUpperCase();
                }

                return "";
            },
            getLabelDomandaFotovoltaico(domanda) {
                if (this.isFotovoltaico) {
                    if (commonLib.isEmpty(this.domande_fotovoltaico[domanda])) return "";

                    return this.domande_fotovoltaico[domanda].label;
                }

                return "";
            },
            getDomandaFotovoltaicoValue(domanda) {
                if (this.isFotovoltaico) {
                    if (commonLib.isEmpty(this.domande_fotovoltaico[domanda])) return "";

                    return this.domande_fotovoltaico[domanda].value.toUpperCase();
                }

                return "";
            },
            getDomandaFotovoltaicoLabel(domanda) {
                if (this.isFotovoltaico) {
                    if (commonLib.isEmpty(this.domande_fotovoltaico[domanda])) return "";
                    return this.analizzaRisposta(this.domande_fotovoltaico[domanda]);

                    //return this.domande_fotovoltaico[domanda].label.toUpperCase();
                }

                return "";
            },
            getLabelDomandaCatastrofali(domanda) {
                if (this.isCatastrofali) {
                    if (commonLib.isEmpty(this.domande_catastrofali[domanda])) return "";

                    return this.domande_catastrofali[domanda].label;
                }

                return "";
            },
            getDomandaCatastrofaliValue(domanda) {
                if (this.isCatastrofali) {
                    if (commonLib.isEmpty(this.domande_catastrofali[domanda])) return "";

                    return this.domande_catastrofali[domanda].value.toUpperCase();
                }

                return "";
            },

            getDomandaStruttureSanitarieValue(domanda) {
                if (this.isStruttureSanitarie) {
                    if (commonLib.isEmpty(this.domande_strutture_sanitarie[domanda])) return "";

                    return this.domande_strutture_sanitarie[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaStruttureSanitarie(domanda) {
                if (this.isStruttureSanitarie) {
                    if (commonLib.isEmpty(this.domande_strutture_sanitarie[domanda])) return "";

                    return this.domande_strutture_sanitarie[domanda].label;
                }

                return "";
            },
            getDomandaShengenValue(domanda) {
                if (this.isShengen) {
                    if (commonLib.isEmpty(this.domande_shengen[domanda])) return "";

                    return this.domande_shengen[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaShengen(domanda) {
                if (this.isShengen) {
                    if (commonLib.isEmpty(this.domande_shengen[domanda])) return "";

                    return this.domande_shengen[domanda].label;
                }

                return "";
            },
            getDomandaAssociazioniValue(domanda) {
                if (this.isAssociazioni) {
                    if (commonLib.isEmpty(this.domande_associazioni[domanda])) return "";

                    return this.domande_associazioni[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaassociazioni(domanda) {
                if (this.isAssociazioni) {
                    if (commonLib.isEmpty(this.domande_associazioni[domanda])) return "";

                    return this.domande_associazioni[domanda].label;
                }

                return "";
            },
            getDomandaAgricolturaValue(domanda) {
                if (this.isMultirischioAgricoltura) {
                    if (commonLib.isEmpty(this.domande_multirischio_agricoltura[domanda])) return "";

                    return this.domande_multirischio_agricoltura[domanda].value.toUpperCase();
                }

                return "";
            },
            getLabelDomandaAgricoltura(domanda) {
                if (this.isMultirischioAgricoltura) {
                    if (commonLib.isEmpty(this.domande_multirischio_agricoltura[domanda])) return "";

                    return this.domande_multirischio_agricoltura[domanda].label;
                }

                return "";
            },


            getElencoGaranzieAggiuntive(prodotto_id) {
                var prodotto = null;

                if (prodotto_id === "infortuni") prodotto = this.prodotto_infortuni;
                if (prodotto_id === "multirischio_casa") prodotto = this.prodotto_casa;
                if (prodotto_id === "multirischio_commercio") prodotto = this.prodotto_commercio;
                if (prodotto_id === "multirischio_artigiano") prodotto = this.prodotto_artigiano;
                if (prodotto_id === "globale_fabbricato") prodotto = this.prodotto_fabbricato;
                if (prodotto_id === "tutela_reddito") prodotto = this.prodotto_tutela_reddito;
                if (prodotto_id === "multirischio_albergo") prodotto = this.prodotto_albergo;
                if (prodotto_id === "deo") prodotto = this.prodotto_deo;
                if (prodotto_id === "viaggi") prodotto = this.prodotto_viaggi;
                if (prodotto_id === "leasing") prodotto = this.prodotto_leasing;
                if (prodotto_id === "patrimoniale") prodotto = this.prodotto_patrimoniale;
                if (prodotto_id === "multirischio_ufficio") prodotto = this.prodotto_ufficio;
                if (prodotto_id === "rc_impresa_edile") prodotto = this.prodotto_rcedilizia;
                if (prodotto_id === "tcm") prodotto = this.prodotto_tcm;
                if (prodotto_id === "tutela_legale_medici") prodotto = this.prodotto_tutela_legale_medici;
                if (prodotto_id === "tutela_legale_professionisti") prodotto = this.prodotto_tutela_legale_professionisti;
                if (prodotto_id === "spese_veterinarie") prodotto = this.prodotto_veterinaria;

                if (prodotto_id === "cauzioni") prodotto = this.prodotto_cauzioni;
                if (prodotto_id === "asseverazioni") prodotto = this.prodotto_asseverazioni;
                if (prodotto_id === "fideiussione") prodotto = this.prodotto_fideiussioni;
                if (prodotto_id === "fotovoltaico") prodotto = this.prodotto_fotovoltaico;
                if (prodotto_id === "donazioni") prodotto = this.prodotto_donazioni;
                if (prodotto_id === "droni") prodotto = this.prodotto_droni;
                if (prodotto_id === 'strutture_sanitarie') prodotto = this.prodotto_strutture_sanitarie;
                if (prodotto_id === 'shengen') prodotto = this.prodotto_shengen;
                if (prodotto_id === 'associazioni') prodotto = this.prodotto_associazioni;
                if (prodotto_id === 'multirischio_agricoltura') prodotto = this.prodotto_agricoltura;

                var proposte = prodotto[0].proposte;
                // Cerca quella selezionata
                var proposta = proposte.find(p => {
                    return p.is_checked;
                });

                return proposta.parametri;
            },
            getCompagniaSelezionata(prodotto_id) {
                var result = "";
                if (commonLib.isEmpty(this.formPreventivo)) return result;

                var prodotto = null;

                if (prodotto_id === "infortuni") prodotto = this.prodotto_infortuni;
                if (prodotto_id === "multirischio_casa") prodotto = this.prodotto_casa;
                if (prodotto_id === "multirischio_commercio") prodotto = this.prodotto_commercio;
                if (prodotto_id === "multirischio_artigiano") prodotto = this.prodotto_artigiano;
                if (prodotto_id === "globale_fabbricato") prodotto = this.prodotto_fabbricato;
                if (prodotto_id === "tutela_reddito") prodotto = this.prodotto_tutela_reddito;
                if (prodotto_id === "multirischio_albergo") prodotto = this.prodotto_albergo;
                if (prodotto_id === "deo") prodotto = this.prodotto_deo;
                if (prodotto_id === "viaggi") prodotto = this.prodotto_viaggi;
                if (prodotto_id === "leasing") prodotto = this.prodotto_leasing;
                if (prodotto_id === "patrimoniale") prodotto = this.prodotto_patrimoniale;
                if (prodotto_id === "multirischio_ufficio") prodotto = this.prodotto_ufficio;
                if (prodotto_id === "rc_impresa_edile") prodotto = this.prodotto_rcedilizia;
                if (prodotto_id === "tcm") prodotto = this.prodotto_tcm;
                if (prodotto_id === "tutela_legale_medici") prodotto = this.prodotto_tutela_legale_medici;
                if (prodotto_id === "tutela_legale_professionisti") prodotto = this.prodotto_tutela_legale_professionisti;
                if (prodotto_id === "spese_veterinarie") prodotto = this.prodotto_veterinaria;

                if (prodotto_id === "cauzioni") prodotto = this.prodotto_cauzioni;
                if (prodotto_id === "asseverazioni") prodotto = this.prodotto_asseverazioni;
                if (prodotto_id === "fideiussione") prodotto = this.prodotto_fideiussioni;
                if (prodotto_id === "fotovoltaico") prodotto = this.prodotto_fotovoltaico;
                if (prodotto_id === "donazioni") prodotto = this.prodotto_donazioni;
                if (prodotto_id === "droni") prodotto = this.prodotto_droni;
                if (prodotto_id === 'strutture_sanitarie') prodotto = this.prodotto_strutture_sanitarie;
                if (prodotto_id === 'shengen') prodotto = this.prodotto_shengen;
                if (prodotto_id === 'associazioni') prodotto = this.prodotto_associazioni;
                if (prodotto_id === 'multirischio_agricoltura') prodotto = this.prodotto_agricoltura;

                var proposte = prodotto[0].proposte;
                // Cerca quella selezionata
                var proposta = proposte.find(p => {
                    return p.is_checked;
                });

                //console.log("proposta:",proposta);

                if (!commonLib.isEmpty(proposta))
                    result =  proposta.compagnia;
                return result;
            },

        },
        computed: {
            ...mapFields('gestioneQuotazioni', {
                domande_multirischio_casa: "questionari_assuntivi.multirischio_casa.domande",
                domande_infortuni: "questionari_assuntivi.infortuni.domande",
                domande_multirischio_commercio: "questionari_assuntivi.multirischio_commercio.domande",
                domande_multirischio_artigiano: "questionari_assuntivi.multirischio_artigiano.domande",
                domande_globale_fabbricato: "questionari_assuntivi.globale_fabbricato.domande",
                domande_tutela_reddito: "questionari_assuntivi.tutela_reddito.domande",
                domande_veterinaria: "questionari_assuntivi.spese_veterinarie.domande",
                domande_multirischio_albergo: "questionari_assuntivi.multirischio_albergo.domande",
                domande_viaggi: "questionari_assuntivi.viaggi.domande",
                domande_leasing: "questionari_assuntivi.leasing.domande",
                domande_patrimoniale: "questionari_assuntivi.patrimoniale.domande",
                domande_multirischio_ufficio: "questionari_assuntivi.multirischio_ufficio.domande",
                domande_rc_edilizia: "questionari_assuntivi.rc_impresa_edile.domande",
                domande_tcm: "questionari_assuntivi.tcm.domande",
                domande_fotovoltaico: "questionari_assuntivi.fotovoltaico.domande",
                domande_catastrofali: "questionari_assuntivi.catastrofali.domande",
                domande_strutture_sanitarie: "questionari_assuntivi.strutture_sanitarie.domande",
                domande_shengen: "questionari_assuntivi.shengen.domande",
                domande_associazioni: "questionari_assuntivi.associazioni.domande",
                domande_multirischio_agricoltura: "questionari_assuntivi.multirischio_agricoltura.domande"
            }),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita,
                analisi_rischio: state => state.formPreventivo.preventivo.analisi_rischio,
                //dati_cliente: state => state.formPreventivo.dati_cliente,
                is_loading: state => state.formPreventivo.is_loading
            }),
            totale_droni() {
                return parseInt(this.getDomandaAnalisiRischioFromName("totale_droni").value);
            },
            estensioni_fotovoltaico() {

                let domanda_attivita_aggiuntive =
                    this.getDomandaAnalisiRischioFromName("estensioni_fotovoltaico");

                // se non trova la domanda nell'analisi rischio
                if (commonLib.isNull(domanda_attivita_aggiuntive)) {
                    return [];
                }

                let risposte = domanda_attivita_aggiuntive.value;
                let attivita = domanda_attivita_aggiuntive.options
                    .filter(opt => {
                        return risposte.includes(opt.value)
                });

                return attivita;
            },
            isQuestionarioBTcmAbilitato() {
                var eta_assicurato = this.getRispostaAnalisiRischioFromName("eta_tcm");
                var capitale_assicurato = parseFloat(this.getRispostaAnalisiRischioFromName("capitale_tcm"));

                // Se ha risposto SI almeno ad una domanda del questionario A allora lo devo visualizzare a prescindere dal resto
                var dati_questionario_A = ((this.getRispostaAnalisiRischioFromName("interrompe_lavoro_tcm") === "SI") ||
                                    (this.getRispostaAnalisiRischioFromName("ricoveri_tcm") === "SI") ||
                                    (this.getRispostaAnalisiRischioFromName("malattie_tcm") === "SI") ||
                                    (this.getRispostaAnalisiRischioFromName("patologie_tcm") === "SI") ||
                                    (this.getRispostaAnalisiRischioFromName("altro_tcm") === "SI")
                                   );

                if (dati_questionario_A) return true;

                switch(eta_assicurato) {
                    case "0_45":
                    case "46_50":
                        if ((capitale_assicurato > 200000) && (capitale_assicurato <= 500000)) return true;
                    break;
                    case "51_55":
                    case "56_60":
                        if ((capitale_assicurato > 100000) && (capitale_assicurato <= 300000)) return true;
                    break;
                    case "61_65":
                        if (capitale_assicurato <= 100000) return true;
                    break;
                    case "66_70":
                        if (capitale_assicurato <= 75000) return true;
                    break;

                }

                return false;
            },
            prodotti_attivi() {
                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked;
                });
            },
            prodotto_infortuni() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "infortuni";
                });
            },
            prodotto_casa() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "multirischio_casa";
                });
            },
            prodotto_commercio() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "multirischio_commercio";
                });
            },
            prodotto_artigiano() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "multirischio_artigiano";
                });
            },
            prodotto_albergo() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "multirischio_albergo";
                });
            },
            prodotto_deo() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "deo";
                });
            },
            prodotto_fabbricato() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "globale_fabbricato";
                });
            },
            prodotto_virus() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "virus";
                });
            },
            prodotto_tutela_reddito() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "tutela_reddito";
                });
            },
            prodotto_viaggi() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "viaggi";
                });
            },
            prodotto_leasing() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "leasing";
                });
            },
            prodotto_patrimoniale() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "patrimoniale";
                });
            },
            prodotto_ufficio() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "multirischio_ufficio";
                });
            },
            prodotto_rcedilizia() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "rc_impresa_edile";
                });
            },
            prodotto_tcm() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "tcm";
                });
            },
            prodotto_tutela_legale_medici() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "tutela_legale_medici";
                });
            },
            prodotto_tutela_legale_professionisti() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "tutela_legale_professionisti";
                });
            },
            prodotto_veterinaria() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "spese_veterinarie";
                });
            },
            prodotto_cauzioni() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "cauzioni";
                });
            },
            prodotto_asseverazioni() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "asseverazioni";
                });
            },
            prodotto_fideiussioni() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "fideiussione";
                });
            },
            prodotto_fotovoltaico() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "fotovoltaico";
                });
            },
            prodotto_donazioni() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "donazioni";
                });
            },
            prodotto_droni() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "droni";
                });
            },
            prodotto_catastrofali() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "catastrofali";
                });
            },
            prodotto_strutture_sanitarie() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "strutture_sanitarie";
                });
            },
            prodotto_shengen() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "shengen";
                });
            },
            prodotto_associazioni() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "associazioni";
                });
            },
            prodotto_agricoltura() {
                var prodotti = this.prodotti_attivi;
                return prodotti.filter(p => {
                    return p.id === "multirischio_agricoltura";
                });
            },

            getIndiceQuestionarioRischio() {
                console.log("indice_questionario_rischio",this.indice_questionario_rischio);
                console.log("prodotti_selezionati",this.prodotti_selezionati);

                var prodotto_id = this.prodotti_selezionati[this.indice_questionario_rischio].id;
                var indice = 0;

                console.log("prodotto_id",prodotto_id);

                for (var i=0;i<this.prodotti_attivi.length;i++) {
                    if (prodotto_id === this.prodotti_attivi[i].id) indice = i;
                }

                return indice;
            },
            isMultirischioCasa() {
                // return !commonLib.isEmpty(this.domande_multirischio_casa);
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_casa");
            },
            isInfortuni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "infortuni");
            },
            isMultirischioCommercio() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_commercio");
            },
            isMultirischioArtigiano() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_artigiano");
            },
            isMultirischioAlbergo() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_albergo");
            },
            isGlobaleFabbricato() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "globale_fabbricato");
            },
            isProdottoVirus() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "virus");
            },
            isProdottoTutelaReddito() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "tutela_reddito");
            },
            isVeterinaria() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "spese_veterinarie");
            },
            isDeO() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "deo");
            },
            isPolizzaViaggi() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "viaggi");
            },
            isPolizzaLeasing() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "leasing");
            },
            isPatrimoniale() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "patrimoniale");
            },
            isMultirischioUfficio() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_ufficio");
            },
            isRCEdilizia() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "rc_impresa_edile");
            },
            isTCM() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "tcm");
            },
            isTutelaLegaleMedici() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "tutela_legale_medici");
            },

            isTutelaLegaleTecnici() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "tutela_legale_professionisti");
            },

            isCauzioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "cauzioni");
            },

            isAsseverazioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "asseverazioni");
            },

            isFideiussioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "fideiussione");
            },

            isFotovoltaico() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "fotovoltaico");
            },

            isDonazioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "donazioni");
            },
            isDroni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "droni");
            },
            isCatastrofali() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "catastrofali");
            },
            isStruttureSanitarie() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "strutture_sanitarie");
            },
            isShengen() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "shengen");
            },
            isAssociazioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "associazioni");
            },
            isMultirischioAgricoltura() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_agricoltura");
            },

            prodotti_selezionati() {
                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked && (p.is_taylormade || p.is_taylor_made);
                });
            },
            ContraenteUgualeAssicurato() {
                if (this.isMultirischioCasa) {
                    if (commonLib.isEmpty(this.domande_multirischio_casa.contraente_uguale_assicurato)) return "SI";

                    return this.domande_multirischio_casa.contraente_uguale_assicurato.value;
                }

                return "SI";
            },
            havePolizzaStessoRischio() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }

                return this.getRispostaAnalisiRischioFromName('polizza_in_corso');
            },
            PolizzaSchifataDaCompagnia() {
                let value = this.getRispostaAnalisiRischioFromName("polizza_schifata");

                return value.toUpperCase();
            },
            IndirizzoCasa() {
                if (this.isMultirischioCasa) {
                    //console.log("domande casa:",this.domande_multirischio_casa);

                    if (commonLib.isEmpty(this.domande_multirischio_casa.indirizzo_casa)) return "";

                    return this.domande_multirischio_casa.indirizzo_casa.value;
                }

                return "";
            },
            ComuneCasa() {
                if (this.isMultirischioCasa) {
                    if (commonLib.isEmpty(this.domande_multirischio_casa.comune_casa)) return "";

                    return this.domande_multirischio_casa.comune_casa.value.toUpperCase();
                }

                return "";
            },
            CapCasa() {
                if (this.isMultirischioCasa) {
                    if (commonLib.isEmpty(this.domande_multirischio_casa.cap_casa)) return "";

                    return this.domande_multirischio_casa.cap_casa.value;
                }

                return "";
            },
            ProvinciaCasa() {
                var domande = this.analisi_rischio[this.indice_questionario_rischio].domande;

                var provincia = domande.find(domanda => {
                    return domanda.name === "provincia_fabbricato";
                });

                if (commonLib.isEmpty(provincia)) return "";

                return provincia.value.replace("_"," ").toUpperCase();
            },
            totale_animali() {
                if (this.isVeterinaria) {
                    var lista = this.analizzaRisposta(this.getDomandaAnalisiRischioFromName('totale_animali_veterinaria')).split(" ");
                    return parseInt(lista[0]);
                }

                return 0;
            }
        },
        async mounted() {
            console.log("Fanculo sono qui in MOUNTED")
            await this.loadQuestionariAssuntivi(this.formPreventivo.preventivo.id);
            //console.log("domande:",this.domande_multirischio_casa);
            let guid_cliente = this.formPreventivo.IDCliente;
            this.dati_cliente = await this.fetchDatiCliente(guid_cliente);
            this.domande_caricate = true;
        },
        async activated() {
            await this.loadQuestionariAssuntivi(this.formPreventivo.preventivo.id);
            //console.log("domande:",this.domande_multirischio_casa);
            let guid_cliente = this.formPreventivo.IDCliente;
            this.dati_cliente = await this.fetchDatiCliente(guid_cliente);
            this.domande_caricate = true;
        },
    }

</script>
