<template>
    <div align="center" v-if="is_mounted">
        <br>
        <div align="center">
            <span class="text-h4">Questionario assuntivo {{ tipo_questionario }}</span>
            <br>
            <strong>Preventivo N. {{quotazione.guid_preventivo}}</strong>
            <hr>
        </div>
        <br><br>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div v-if="isRcTecnico">
            <QQVisualizzaQuestionarioTecnico :indice_questionario_rischio="indice_prodotto" />
        </div>

        <div v-if="isRcMedico">
            <QQVisualizzaQuestionarioMedico  :indice_questionario_rischio="indice_prodotto" />
        </div>

        <div v-if="isRcSanitario">
            <QQVisualizzaQuestionarioSanitario  :indice_questionario_rischio="indice_prodotto" />
        </div>

        <div v-if="isAltroRamo">
            <QQVisualizzaQuestionarioPersoneAziende  :indice_questionario_rischio="indice_prodotto" />
        </div>

        <hr>

        <div class="row" align="center">
            <div class="col-12 col-md-3" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-12 col-md-3" align="center">
                <QQButton :disabled="isRifiutaDisabled" label="RIFIUTA EMISSIONE" color="blue-grey" icon="mdi-close-octagon" size="md"
                    @click.native="onRifiutaEmissione"
                />
            </div>
            <div class="col-12 col-md-3" align="center">
                <QQButton :disabled="isInviaSegnalazioneDisabled" label="INVIA SEGNALAZIONE" color="blue-grey" icon="mdi-arrow-right-bold" size="md"
                    @click.native="onInviaSegnalazione"
                />
            </div>
            <div class="col-12 col-md-3" align="center">
                <QQButton :disabled="inLavorazioneIsDisabled()" label="METTI IN LAVORAZIONE" color="blue-grey" icon="mdi-arrow-right-bold" size="md" icon_align="right"
                    @click.native="onMettiInLavorazione"
                />
            </div>
        </div>

        <br><br><br><br>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import QQVisualizzaQuestionarioTecnico from "@/components/QuestionariAssuntivi/QQVisualizzaQuestionarioTecnico.vue";
    import QQVisualizzaQuestionarioMedico from "@/components/QuestionariAssuntivi/QQVisualizzaQuestionarioMedico.vue";
    import QQVisualizzaQuestionarioSanitario from "@/components/QuestionariAssuntivi/QQVisualizzaQuestionarioSanitario.vue";
    import QQVisualizzaQuestionarioPersoneAziende from "@/components/QuestionariAssuntivi/QQVisualizzaQuestionarioPersoneAziende.vue";
    import commonLib from "@/libs/commonLib";
    import { mapState,mapActions } from "vuex";
    import { mapFields} from "vuex-map-fields";

    export default {
        name: "VisualizzaQuestionarioAssuntivo",
        data() {
            return {
                quotazione: {},
                is_mounted: false,
                is_loading: false
            }
        },
        components: {
            QQVisualizzaQuestionarioTecnico,
            QQVisualizzaQuestionarioMedico,
            QQVisualizzaQuestionarioSanitario,
            QQVisualizzaQuestionarioPersoneAziende,
            QQButton
        },
        computed: {
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                // is_loading: state => state.formPreventivo.is_loading,
                formPreventivo: state => state.formPreventivo,
                analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita
            }),
            tipo_questionario() {
                if (this.isRcTecnico) return "R.C. Tecnico";
                if (this.isRcMedico) return "R.C. Medico";
                if (this.isRcSanitario) return "return R.C. Sanitario non Medico";

                return "";
            },
            isRifiutaDisabled() {
                //console.log("isRifiutaDisabled:",this.quotazione.stato,this.quotazione.stato === "DEFINITA");
                return this.quotazione.stato === "DEFINITA";
            },
            isInviaSegnalazioneDisabled() {
                return this.quotazione.stato === "DEFINITA";
            },

            prodotti_attivi() {
                return this.formPreventivo.preventivo.prodotti.filter(prodotto => {
                    return prodotto.is_checked;
                });
            },
            elenco_prodotti_attivi() {
                if (commonLib.isEmpty(this.formPreventivo)) return [];

                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked && (p.is_taylormade || p.is_taylor_made);
                });
            },

            analisi_rischio() {
                var id_prodotto = this.quotazione.id_prodotto;
                var prodotti_attivi = this.prodotti_attivi;
                // Cerca tra i prodotti attivi quello che ha come tariffa il valore della quotazione

                for (var i=0;i<prodotti_attivi.length;i++) {
                    var prodotto = prodotti_attivi[i];
                    var questionario = this.formPreventivo.preventivo.analisi_rischio[i];
                    if (prodotto.id === id_prodotto) return questionario;
                }

                return [];
            },

            indice_prodotto() {
                var id_prodotto = this.quotazione.id_prodotto;
                var prodotti_attivi = this.elenco_prodotti_attivi;
                var indice = 0;

                //console.log("id_prodotto:",id_prodotto);

                //console.log("prodotti_attivi:",prodotti_attivi);

                // Cerca tra i prodotti attivi quello che ha come tariffa il valore della quotazione

                for (var i=0;i<prodotti_attivi.length;i++) {
                    var prodotto = prodotti_attivi[i];
                    if (prodotto.id === id_prodotto) indice = i;
                }

                //console.log("indice_prodotto:",indice);

                return indice;
            },

            isRcTecnico() {
                return !this.isRcMedico && !this.isRcSanitario && !this.isAltroRamo;
            },
            isRcMedico() {
                let lavoro = this.getRispostaAnalisiRischioFromName("lavoro");
                if (lavoro === "") {
                    lavoro = this.getRispostaAnalisiNecessitaFromName("lavoro");
                }

                return lavoro.toLowerCase() === "medico";
            },
            isRcSanitario() {
                let lavoro = this.getRispostaAnalisiRischioFromName("lavoro");
                if (lavoro === "") {
                    lavoro = this.getRispostaAnalisiNecessitaFromName("lavoro");
                }

                return lavoro.toLowerCase() === "sanitario_non_medico";
            },
            listaTaylormade() {
                return this.formPreventivo.preventivo.prodotti.filter(prodotto => {
                    return prodotto.is_checked && (prodotto.is_taylormade || prodotto.is_taylor_made);
                });
            },
            isAltroRamo() {
                var taylormade = this.listaTaylormade;
                if (taylormade.length === 0) taylormade = this.prodotti_attivi;



                //console.log("taylormade:",taylormade);
                //console.log("indice prodotto:",this.indice_prodotto);

                taylormade = taylormade[this.indice_prodotto];

                return (taylormade.id !== "rcp_tecnico") &&
                       (taylormade.id !== "rcp_medico") &&
                       (taylormade.id !== "rcp_sanitario");
            },
        },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                MettiInLavorazione: "gestioneQuotazioni/MettiInLavorazione"
            }),
            getRispostaAnalisiNecessitaFromName(name) {
                if (!commonLib.isEmpty(this.analisi_necessita)) {
                    var domanda = this.analisi_necessita.find(d => {
                        return d.name === name;
                    });

                    // Se la domanda non e` presente nel questionario allora domanda=undefined
                    if (commonLib.isEmpty(domanda)) return "";

                    return domanda.value.toUpperCase();
                }

                return "";
            },

            getRispostaAnalisiRischioFromName(name) {
                if (commonLib.isEmpty(this.analisi_rischio)) return "";

                if (!commonLib.isEmpty(this.analisi_rischio)) {
                    var domanda = this.analisi_rischio.domande.find(d => {
                        return d.name === name;
                    });

                    if (commonLib.isEmpty(domanda)) return "";

                    var risposta =  domanda.value;

                    if (!Array.isArray(risposta))
                        return risposta.toUpperCase();

                    return risposta;
                }

                return "";
            },

            onIndietroClicked() {
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            },
            onRifiutaEmissione() {
                this.$router.push({name : "Quotazioni.RifiutaQuotazione"});
            },
            onInviaSegnalazione() {
                this.$router.push({name : "Quotazioni.InviaSegnalazione"});
            },
            async onMettiInLavorazione() {
                this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Metto la quotazione in stato LAVORAZIONE?',
                        cancel: {
                        focus: true,
                        flat: false,
                        label: "NO",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, procedi"
                    },
                    }).onOk(async () => {
                        this.is_loading = true;

                        await this.MettiInLavorazione(
                            {
                                guid_preventivo: this.quotazione.guid_preventivo,
                                id_quotazione: this.quotazione.idquotazione
                            }
                        );

                        this.is_loading = true;

                        this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
                    }).onCancel(() => {

                    }).onDismiss(() => {

                    })
            },
            async load_quotazioni() {
                this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            },
            inLavorazioneIsDisabled() {
                if (this.is_loading) return true;
                return (this.quotazione.stato.trim() !== "NUOVA");
            },
        },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            this.is_mounted = true;

            console.log("quotazione:",this.quotazione);
        },
    /*    async activated() {
            //this.indice_prodotto = 0;
            //console.log("activated di VisualizzaQuestionarioAssuntivo");
            //console.log("formPreventivo:",this.formPreventivo);

            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);

           // console.log("Quotazione:",this.quotazione);
        } */
    }
</script>
