<template>
    <div class="row justify-center" v-if="questionario_caricato">

        <div class="col-12 col-md-10" align="left">
            <legend> <strong>Proponente - Informazioni generali</strong> </legend>

            <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Cognome, Nome / Ragione Sociale</q-item-label>
                        <q-item-label>{{getDatiCliente("nominativo")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Indirizzo</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("indirizzo")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Civico</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("civico")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("comune")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("provincia")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>CAP</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("cap")}}</q-item-label>
                    </q-item-section>
                </q-item>

                <q-item>
                    <q-item-section>
                        <q-item-label caption>Codice Fiscale</q-item-label>
                        <q-item-label>{{getDatiCliente("codice_fiscale")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Partita IVA</q-item-label>
                        <q-item-label>{{getDatiCliente("partita_iva")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune di nascita</q-item-label>
                        <q-item-label>{{getDatiCliente("comune_nascita")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia di nascita</q-item-label>
                        <q-item-label>{{getDatiCliente("provincia_nascita")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Attività svolta</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("lavoro")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Tipo attività</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("tipo_lavoro")}}</q-item-label>
                    </q-item-section>
                   <q-item-section v-if="isSindacoRevisore">
                        <q-item-label caption>Svolgi attivit&agrave; di Sindaco Revisore?</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("sindaco_revisore")}}</q-item-label>
                    </q-item-section>
                    <q-item-section v-if="isSindacoRevisore">
                        <q-item-label caption>A quale percentuale corrisponde il fatturato di Sindaco/Revisore?</q-item-label>
                        <q-item-label>{{getPercentualeSindacoRevisore}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Sei un professionista iscritto ad un albo e/o abilitato all'esercizio dell'attivita` professionale?</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("iscrizione_albo")}}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>

            <br>
            <legend> <strong>Proponente - Dati professionali</strong> </legend>

            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomandaLabel("data_inizio_attivita")}}</q-item-label>
                        <q-item-label>{{getObjectDomandaValue("data_inizio_attivita")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomandaLabel("data_iscrizione_albo")}}</q-item-label>
                        <q-item-label>{{getObjectDomandaValue("data_iscrizione_albo")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomandaLabel("numero_iscrizione_albo")}}</q-item-label>
                        <q-item-label>{{getObjectDomandaValue("numero_iscrizione_albo")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomandaLabel("sezione_albo")}}</q-item-label>
                        <q-item-label>{{getObjectDomandaValue("sezione_albo")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomandaLabel("data_ultimo_aggiornamento_prof")}}</q-item-label>
                        <q-item-label>{{getObjectDomandaValue("data_ultimo_aggiornamento_prof")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("numero_crediti_conseguiti").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("numero_crediti_conseguiti").value}}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>

            <br>
            <legend> <strong>Caratteristiche generali</strong> </legend>
            <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label caption v-show="!isTaylorMade">Compagnia</q-item-label>
                        <q-item-label v-show="!isTaylorMade">
                            {{getCompagniaSelezionata}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive" v-bind:key="index">
                    <q-item-section>
                        <q-item-label caption v-show="!isTaylorMade">{{garanzia.label}}</q-item-label>
                        <q-item-label v-show="!isTaylorMade">
                            {{analizzaRisposta(garanzia)}}
                        </q-item-label>
                    </q-item-section>
                </q-item>

                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("chiude_attivita").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("chiude_attivita").value}}</q-item-label>
                    </q-item-section>
                    <q-item-section v-if="domandaIsVisible(getObjectDomanda('vuole_postuma'))">
                        <q-item-label caption>{{getObjectDomanda("vuole_postuma").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("vuole_postuma").value}}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>

            <br>
            <legend> <strong>Informazioni sul fatturato</strong> </legend>
             <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("fatturato.fatturato_corrente").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("fatturato.fatturato_corrente").value}} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Fatturato consuntivo {{(new Date().getFullYear() - 1)}}</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName('fatturato')}} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("fatturato.fatturato_due_anni_fa").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("fatturato.fatturato_due_anni_fa").value}} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("fatturato.fatturato_tre_anni_fa").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("fatturato.fatturato_tre_anni_fa").value}} Euro</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Hai un Cliente su cui fatturi piu` del 50% del fatturato?</q-item-label>
                        <q-item-label>
                            {{rispostaClientePiu50Fatturato}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <div v-show="haveClientePiu50Fatturato">
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda("cliente_50_percento.nominativo").label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda("cliente_50_percento.nominativo").value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda("cliente_50_percento.settore_attivita").label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda("cliente_50_percento.settore_attivita").value}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda("cliente_50_percento.altro").label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda("cliente_50_percento.altro").value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda("cliente_50_percento.perc_fatturato").label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda("cliente_50_percento.perc_fatturato").value}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>
            </q-list>

            <br>
            <legend><strong>Distribuzione del fatturato per Attivit&agrave;</strong></legend>
            <br>
            <q-list bordered separator>
                <q-item>
                    <q-item-section>
                        <q-item-label><strong>ATTIVIT&Agrave;</strong></q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label><strong>Suddivisione Percentuale Fatturato Anno precedente</strong></q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label><strong>Suddivisione Percentuale Fatturato Anno corrente</strong></q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label><strong>Attivita ordinaria</strong></q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{percentuale_fatturato_attivita_ordinaria_anno_precedente()}} %</q-item-label>
                        <q-item-label caption>{{getImportoPrecedente(percentuale_fatturato_attivita_ordinaria_anno_precedente())}} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{percentuale_fatturato_attivita_ordinaria_anno_corrente()}} %</q-item-label>
                        <q-item-label caption>{{getImportoCorrente(percentuale_fatturato_attivita_ordinaria_anno_corrente())}} Euro</q-item-label>
                    </q-item-section>
                </q-item>

                <div v-for="(attivita,index) in getObjectDomanda('distribuzione_fatturato')" v-bind:key="index">
                    <q-item v-if="domandaIsVisible(attivita.perc_anno_corrente)">
                        <q-item-section>
                            <q-item-label>{{attivita.label.label}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{attivita.perc_anno_precedente.value}} %</q-item-label>
                            <q-item-label caption>{{getImportoPrecedente(attivita.perc_anno_precedente.value)}} Euro</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{attivita.perc_anno_corrente.value}} %</q-item-label>
                            <q-item-label caption>{{getImportoPrecedente(attivita.perc_anno_corrente.value)}} Euro</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>
            </q-list>

            <br>
            <legend><strong>Fatturato internazionale</strong></legend>
            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Percentuale fatturato  Italia</q-item-label>
                        <q-item-label>{{ percentualeFatturatoItalia }} %</q-item-label>
                        <q-item-label caption>{{getImportoFatturatoEstero(percentualeFatturatoItalia) }} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("fatturato_estero.perc_fatturato_ue").label}}</q-item-label>
                        <q-item-label>{{ getObjectDomanda("fatturato_estero.perc_fatturato_ue").value }} %</q-item-label>
                        <q-item-label caption>{{getImportoFatturatoEstero(getObjectDomanda("fatturato_estero.perc_fatturato_ue").value) }} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("fatturato_estero.perc_fatturato_non_ue").label}}</q-item-label>
                        <q-item-label>{{ getObjectDomanda("fatturato_estero.perc_fatturato_non_ue").value }} %</q-item-label>
                        <q-item-label caption>{{getImportoFatturatoEstero(getObjectDomanda("fatturato_estero.perc_fatturato_non_ue").value) }} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section v-show="lavoraUsaCanada">
                        <q-item-label caption>{{getObjectDomanda("fatturato_estero.perc_fatturato_usa_canada").label}}</q-item-label>
                        <q-item-label>{{ getObjectDomanda("fatturato_estero.perc_fatturato_usa_canada").value }} %</q-item-label>
                        <q-item-label caption>{{getImportoFatturatoEstero(getObjectDomanda("fatturato_estero.perc_fatturato_usa_canada").value) }} Euro</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>

            <br>
            <div v-show="ha_funzioni_pubbliche">
                <legend> <strong>Estensione funzioni pubbliche</strong> </legend>
                <br>
                <q-list bordered separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Si prega di specificare le funzioni ricoperte dall'Assicurato e le relative anzianit&agrave;</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label><strong>DESCRIZIONE</strong></q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label><strong>DATA INIZIO ATTIVIT&Agrave;</strong></q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item v-for="(dato,index) in Object.values(getObjectDomanda('funzioni_pubbliche'))" v-bind:key="index" class="q-mx-sm q-px-sm">

                        <q-item-section>
                            {{dato['funz_pubbliche_descrizione'+(index+1)].value}}
                        </q-item-section>
                        <q-item-section>
                            {{dato['funz_pubbliche_datadal'+(index+1)].value}}
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

           <Domanda
                :domanda="getObjectDomanda('dati_consigliere_amministrazione')"
                ref="domande.dati_consigliere_amministrazione"
                disabled
            />
            <br>

            <div v-show="isSindacoRevisore">
                <legend><strong>Società o Enti nelle quali il proponente è attualmente Sindaco revisore dei conti, Amministratore di società , Attività ODV</strong></legend>
                <br>
                <q-list bordered separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label><strong>SOCIET&Agrave;</strong></q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label><strong>Capitale sociale</strong></q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label><strong>Fatturato</strong></q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label><strong>Settore attivit&agrave;</strong></q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label><strong>Ruolo</strong></q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label><strong>Societ&agrave; o controllante quotate in borsa</strong></q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label><strong>Data nomina</strong></q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item v-for="(dato,index) in Object.values(getObjectDomanda('dati_sindaco'))" v-bind:key="index" class="q-mx-sm q-px-sm">
                         <q-item-section>
                            <q-item-label>{{dato['dati_sindaco_societa'+(index+1)].value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{dato['dati_sindaco_capitale_sociale'+(index+1)].value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{dato['dati_sindaco_fatturato'+(index+1)].value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{dato['dati_sindaco_settore'+(index+1)].value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{dato['dati_sindaco_ruolo'+(index+1)].value}}</q-item-label>
                        </q-item-section>
                       <q-item-section>
                            <q-item-label>{{dato['dati_sindaco_quotate_borsa'+(index+1)].value}}</q-item-label>
                        </q-item-section>
                         <q-item-section>
                            <q-item-label>{{dato['dati_sindaco_data_nomina'+(index+1)].value}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

            <br>
            <legend><strong>Precedenti assicurazioni per questo rischio</strong></legend>
             <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Hai mai stipulato polizze per questo rischio?</q-item-label>
                        <q-item-label>
                            {{havePolizzaStessoRischio}}
                        </q-item-label>
                    </q-item-section>
                </q-item>

                <div v-show="havePolizzaStessoRischio === 'SI' ">
                    <q-item >
                        <q-item-section>
                            <q-item-label caption>Impresa di assicurazione</q-item-label>
                            <q-item-label>{{getRispostaQuestionariFromName('compagnia')}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>Data di scadenza della copertura</q-item-label>
                            <q-item-label>{{getRispostaQuestionariFromName('scadenza_copertura')}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.nome_assicuratore').label}}</q-item-label>
                                <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.nome_assicuratore').value}}&nbsp;</q-item-label>
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.massimale').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.massimale').value}} Euro</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.franchigie_scoperti').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.franchigie_scoperti').value}}&nbsp;</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.premio_lordo').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.premio_lordo').value}} Euro</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.retroattivita').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.retroattivita').value}}&nbsp;</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>
                <q-item>
                    <q-item-section >
                        <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                        <q-item-label>{{PolizzaSchifataDaCompagnia}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section v-if="PolizzaSchifataDaCompagnia === 'SI'">
                        <q-item-label caption>{{getObjectDomanda('motivo_rifiuto_compagnia').label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda('motivo_rifiuto_compagnia').value}}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>

            <br>
            <legend><strong>Informazioni sui sinistri</strong></legend>
            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Hai mai ricevuto richieste di risarcimento inerenti l`attività professionale?</q-item-label>
                        <q-item-label>
                            {{haveRichiesteRisarcimento}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <div v-show="haveRichiesteRisarcimento === 'SI'">
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.data_richiesta_risarcimento').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.data_richiesta_risarcimento').value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.periodo_contestazione').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.periodo_contestazione').value}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.valore_richiesta_danni').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.valore_richiesta_danni').value}} Euro</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.valore_sinistro_pagato').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.valore_sinistro_pagato').value}} Euro</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.pagamento_con_transazione').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.pagamento_con_transazione').value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.pagamento_con_sentenza_civile').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.pagamento_con_sentenza_civile').value}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.descrizione_fatti').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.descrizione_fatti').value}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>

                <q-item>
                    <q-item-section>
                        <q-item-label caption>Sei a conoscenza di circostanze che possono portare ad una richiesta di risarcimento da parte di clienti?</q-item-label>
                        <q-item-label>
                            {{haveConoscenzaPossibiliRichiesteRisarcimento}}
                        </q-item-label>
                    </q-item-section>
                </q-item>

                <q-item v-show="haveConoscenzaPossibiliRichiesteRisarcimento === 'SI'">
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda('sinistri.descrizione_possibili_richieste_danni').label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda('sinistri.descrizione_possibili_richieste_danni').value}}</q-item-label>
                    </q-item-section>
                </q-item>

            </q-list>


            <br><br><br><br><br>
        </div>

    </div>
</template>

<script>
    //import QQButton from "@/components/QQButton.vue";
    import { mapState,mapActions } from "vuex";
    import { mapFields} from "vuex-map-fields";
    import commonLib from "../../libs/commonLib";
    import DomandaQuestionarioAssuntivo from "@/components/QuestionariAssuntivi/DomandaQuestionarioAssuntivo";

    //import { mapGetters } from "vuex";

    export default {
        name: "QQVisualizzaQuestionarioTecnico",
        components: {
            Domanda: DomandaQuestionarioAssuntivo
        },
        data() {
            return {
                dati_quotazione: {},
                dati_cliente: {},
                questionario_caricato : false
            }
        },
        props: {
            indice_questionario_rischio: {
                type: Number,
                required: false,
                default: 0
            }
        },
        computed: {
            ...mapFields('gestioneQuotazioni', {
                domande: "questionari_assuntivi.rcp_tecnico.domande"
            }),
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita,
                analisi_rischio: state => state.formPreventivo.preventivo.analisi_rischio,
                is_loading: state => state.formPreventivo.is_loading
            }),
            haveRichiesteRisarcimento() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }
                // return this.analisi_rischio[0].domande[9].value.toUpperCase();
                return this.getRispostaQuestionariFromName('richieste_risarcimento');
            },
            haveConoscenzaPossibiliRichiesteRisarcimento() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }
                // return this.analisi_rischio[0].domande[11].value.toUpperCase();
                return this.getRispostaQuestionariFromName('rcp_reclami_in_corso');
            },
            PolizzaSchifataDaCompagnia() {
                let value = this.getRispostaQuestionariFromName("polizza_schifata");
                if (value === "") value = "NO";

                return value.toUpperCase();
            },
            havePolizzaStessoRischio() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }

                //return this.analisi_rischio[0].domande[1].value.toUpperCase();
                return this.getRispostaQuestionariFromName('polizza_in_corso');
            },
            lavoraUsaCanada() {
                let risposta_lavora_usa_canada =
                    this.getRispostaQuestionariFromName("area_geografica");

                return "SI" === risposta_lavora_usa_canada.toUpperCase();
            },
            percentualeFatturatoItalia() {
                if (commonLib.isEmpty(this.domande)) return 100;

                return 100 - (parseInt(this.domande.fatturato_estero.perc_fatturato_ue.value) +
                              parseInt(this.domande.fatturato_estero.perc_fatturato_non_ue.value) +
                              parseInt(this.domande.fatturato_estero.perc_fatturato_usa_canada.value));
            },
            haveClientePiu50Fatturato() {
                var result = false;

                // var valori = this.analisi_rischio[0].domande[7].value;
                var valori = this.getRispostaQuestionariFromName('estensioni_rc_tecnico');

                for (var i=0;i<valori.length;i++) {
                    // var valore = this.analisi_rischio[0].domande[7].value[i];
                    var valore = valori[i];
                    result = (valore === "FATTURATO_OLTRE_50_SINGOLO_CLIENTE");

                    if (result) return true;
                }

                return result;
            },
            rispostaClientePiu50Fatturato() {
                if (this.haveClientePiu50Fatturato) return "SI";

                return "NO";
            },
            prodotti_selezionati() {
                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked;
                });
            },

            isTaylorMade() {
                /*var prodotti = this.prodotti_selezionati;

                console.log("prodotti:",prodotti[this.indice_questionario_rischio]);
                return prodotti[this.indice_questionario_rischio];*/
                return false;
            },
            getCompagniaSelezionata() {
                var result = "";
                if (commonLib.isEmpty(this.formPreventivo)) return result;

                for (var i=0;i< this.formPreventivo.preventivo.prodotti.length;i++) {
                    var prodotto = this.formPreventivo.preventivo.prodotti[i];
                    if ((prodotto.label === "RC Settore Tecnico") &&
                        (prodotto.is_checked)) {
                        var proposte = prodotto.proposte.filter(proposta => {
                            return proposta.is_checked;
                        });

                        if (!commonLib.isEmpty(proposte[0]))
                            result =  proposte[0].compagnia;
                    }
                }

                return result;
            },
            getElencoGaranzieAggiuntive() {
                if (commonLib.isEmpty(this.formPreventivo)) return [];
                var prodotti = this.formPreventivo.preventivo.prodotti;

                if (!Array.isArray(prodotti)) return [];

                var prodotto_attivo = prodotti.find(p => {
                    return (p.label === "RC Settore Tecnico") && (p.is_checked);
                });
                var proposte = prodotto_attivo.proposte;
                // Cerca quella selezionata
                var proposta = proposte.find(p => {
                    return p.is_checked;
                })

                return proposta.parametri;
            },
            attivita_aggiuntive_da_analisi_rischio() {

                let domanda_attivita_aggiuntive =
                    this.getDomandaQuestionariFromName("estensioni_rc_tecnico");

                // se non trova la domanda nell'analisi rischio
                if (commonLib.isNull(domanda_attivita_aggiuntive)) {
                    return [];
                }

                // ["AMMINISTRATORE_STABILI","ARBITRO"]
                let risposte = domanda_attivita_aggiuntive.value;

                //let attivita = domanda_attivita_aggiuntive.options
                //    .filter(opt => risposte.includes(opt.value));

                let attivita = domanda_attivita_aggiuntive.options
                    .filter(opt => {
                        return risposte.includes(opt.value)
                });

                return attivita;
            },
            isSindacoRevisore() {
                let risposta = this.attivita_aggiuntive_da_analisi_rischio
                    .find(a=>a.value === "SINDACO_REVISORE");

                if (commonLib.isEmpty(risposta)) {
                    // Se non la trova nell'analisi del rischio, cerca in analisi necessita
                    // dove attivita come il commercialista la indicano
                    //risposta = this.getRispostaAnalisiNecessita("sindaco_revisore");
                    risposta = this.getRispostaQuestionariFromName("sindaco_revisore");
                }

                //return commonLib.isNotNull(risposta);
                return !commonLib.isEmpty(risposta);
            },
            getPercentualeSindacoRevisore() {
                var risposta = this.getRispostaQuestionariFromName("percentuale_sindaco_revisore");

                if (commonLib.isEmpty(risposta)) return "";

                switch(risposta) {
                    case "FINO_50": return "Fino al 25% del fatturato";
                    //default: return "Oltre il 25% del fatturato";
                }

                return "Oltre il 25% del fatturato";
            },
            fatturato_totale() {
                let fatturato = this.getRispostaQuestionariFromName("fatturato");
                return parseInt(fatturato);
            },
            ha_funzioni_pubbliche() {
                let attivita_funzioni_pubbliche = [
                    "FUNZIONI_COMMISSIONI_TRIBUTARIE",
                    "CURATORE_LIQUIDATORE_COMMISSARIO",
                    "DELEGATO_VENDITE",
                    "FUNZIONI_PUBBLICHE_GIUDIZIALI"
                ]

                let funzioni_pubbliche_attive =
                    this.attivita_aggiuntive_da_analisi_rischio
                        .filter(a=>attivita_funzioni_pubbliche.includes(a.value));

                return funzioni_pubbliche_attive.length > 0;
            },
        },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                fetchDatiCliente: "gestioneClienti/fetchDatiCliente",
                loadQuestionariAssuntivi: "gestioneQuotazioni/loadQuestionariAssuntivi"
            }),
            getDatiCliente(index) {
                if (commonLib.isEmpty(this.dati_cliente)) return "";

                return this.dati_cliente[index];
            },
            getObjectDomanda(domanda) {

                if (commonLib.isEmpty(this.domande)) return {};

                var elements = domanda.split(".");
                if (elements.length > 0) {
                    var result = this.domande;
                    elements.forEach(el => {
                        result = result[el];
                    });

                    return result;
                }

                return this.domande[domanda];
            },
            getObjectDomandaValue(domanda) {
                var domandaObj = this.getObjectDomanda(domanda);

                if (commonLib.isEmpty(domandaObj.value)) return "";

                return domandaObj.value;
            },
            getObjectDomandaLabel(domanda) {
                var domandaObj = this.getObjectDomanda(domanda);

                if (commonLib.isEmpty(domandaObj.label)) return "";

                return domandaObj.label;
            },
            getImportoFatturatoEstero(perc) {
                return parseInt(this.fatturato_totale * perc / 100);
            },
            getDomandaAnalisiNecessitaFromName(name) {
                // non c'è analisi necessita
                if (commonLib.isEmpty(this.analisi_necessita))
                    return null;

                // cerca la domanda
                var domanda = this.analisi_necessita.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },
            condizioneIsValidaInDomanda(condizione, domanda)
            {
                // Recupera i nome del campo di riferimento
                // let NomeCampo = condizione.split(":")[0];
                let ValoreCampo = condizione.split(":")[1];

                if (domanda === null) return true;

                // la domanda a cui la condizione fa riferimento non è visibile
                if(!this.domandaIsVisible(domanda)) {
                    //if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                    //    console.log("condizioneIsValida.perc_anno_corrente !domandaIsVisible");
                   // }
                    return false;
                }

                // il value non è valorizzato
                if(commonLib.isNullOrEmpty(domanda.value)) {
                    //if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                    //    console.log("condizioneIsValida.perc_anno_corrente isNullOrEmpty(domanda.value)");
                    //}
                    return false;
                }

                // la risposta è una stringa, ci basta controllare che la
                // condizione contenga la stringa
                // es. risposta "ciccio", condizione "ciccio,pasticcio,mario"
                if(typeof domanda.value === "string") {
                    return ValoreCampo.includes(domanda.value);
                }

                // se la risposta alla domanda è un array, controlliamo che
                // la condizione sia contenuta nell'array di risposta

                // ValoreCampo = val1,val2,val3,val4
                // domanda.value = ["val5", "val1"]
                if(ValoreCampo.indexOf(",") > 1) {
                    var elenco = ValoreCampo.Split(",");
                    var result = true;
                    elenco.forEach(item => {
                        result &= domanda.value.includes(item);
                    });

                    return result;
                }

                return domanda.value.includes(ValoreCampo);
            },
            condizioneIsValida(condizione)
            {
                // Recupera i nome del campo di riferimento
                let NomeCampo = condizione.split(":")[0];
                // let ValoreCampo = condizione.split(":")[1];


                // domanda da controllare è in questionario assuntivo
                if(!commonLib.isEmpty(this.domande[NomeCampo])) {

                //    if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                //        console.log("condizioneIsValida.perc_anno_corrente","questionario assuntivo" );
                //    }

                    let domanda = this.domande[NomeCampo];
                    return this.condizioneIsValidaInDomanda(condizione, domanda);
                }

                // domanda da controllare non è nel questionario assuntivo

                // è in analisi rischio
                var domanda_da_analisi_rischio = this.getDomandaQuestionariFromName(NomeCampo);
                if(domanda_da_analisi_rischio !== null) {
                    return this.condizioneIsValidaInDomanda(condizione, domanda_da_analisi_rischio);
                }

                // è in analisi necessità
                var domanda_da_analisi_necessita = this.getDomandaQuestionariFromName(NomeCampo);
                if(domanda_da_analisi_necessita !== null) {
                    return this.condizioneIsValidaInDomanda(condizione, domanda_da_analisi_necessita);
                }

                // la domanda a cui si fa riferimento non c'è da nessuna parte
                return false;
            },
            domandaIsVisible(domanda)
            {
                if (commonLib.isEmpty(domanda)) {
                    return true;
                }

                if (!domanda.is_visible) {
                    return false;
                }

                if (commonLib.isEmpty(domanda.is_visible_if)) {
                    return true;
                }

                // splitta condizioni separate da punto e virgola
                let condizioni = domanda.is_visible_if.split(";");
                let condizioni_valide = true;

                condizioni.forEach(cond => {

                    // se la condizione è valida, prosegui
                    if(this.condizioneIsValida(cond)) {
                        return;
                    }

                    // la condizione non è valida
                    condizioni_valide = false;
                    return false;
                });

                // console.log("domandaIsVisible", domanda.name, condizioni_valide);

                return condizioni_valide;
            },
            getImportoPrecedente(perc) {
                var fatturato = parseInt(this.getRispostaQuestionariFromName("fatturato"));
                var importo = fatturato * parseInt(perc) / 100;

                return parseInt(importo);
            },
            getImportoCorrente(perc) {
                if (commonLib.isEmpty(this.domande)) return 0;

                var fatturato = parseInt(this.domande.fatturato.fatturato_corrente.value);
                var importo = fatturato * parseInt(perc) / 100;

                return parseInt(importo);
            },
            percentuale_fatturato_attivita_ordinaria_anno_precedente() {
                var percentuale = 0;

                //console.log("distribuzione_fatturato:",this.domande.distribuzione_fatturato);

                // Aggiusta la percentuale totale in base alle singole percentuali inserite
                if (!commonLib.isEmpty(this.domande)) {

                    // Itera dentro l'oggetto
                    for (var property in this.domande.distribuzione_fatturato) {
                        var element = this.domande.distribuzione_fatturato[property];

                        percentuale += parseInt(element.perc_anno_precedente.value);
                    }
                }

                return 100 - parseInt(percentuale);
            },
            percentuale_fatturato_attivita_ordinaria_anno_corrente() {
                var percentuale = 0;

                // Itera dentro l'oggetto
                for (var property in this.domande.distribuzione_fatturato) {
                    var element = this.domande.distribuzione_fatturato[property];

                    percentuale += parseInt(element.perc_anno_corrente.value);
                }

                return 100 - parseInt(percentuale);
            },
            analizzaRisposta(garanzia) {
                // type,value,options
                let result = "";
                let value = garanzia.value;
                let options = garanzia.options;
                let type = garanzia.type;


                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                });

                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        if (type === "checkbox") return garanzia.is_checked ? "SI" : "NO";

                        return value.replace("_"," ");
                    } else
                        return "";
                }
            },
            getIndiceQuestionarioRischio() {
                var prodotto_id = "rcp_tecnico";
                var indice = 0;

                for (var i=0;i<this.prodotti_selezionati.length;i++) {
                    if (prodotto_id === this.prodotti_selezionati[i].id) indice = i;
                }

                return indice;
            },
            getDomandaAnalisiRischioFromName(name) {
                // analisi rischio RC sempre in posizione 0
                // TODO: andare per id

                // non c'è analisi rischio
                if (commonLib.isEmpty(this.analisi_rischio))
                    return null;

                // non ci sono domande analisi rischio
                let indice = this.getIndiceQuestionarioRischio();
                let analisi_rischio_rc = this.analisi_rischio[indice];
                if (commonLib.isEmpty(analisi_rischio_rc)) {
                    return null;
                }

                // cerca la domanda
                var domanda = analisi_rischio_rc.domande.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },
            getRispostaAnalisiRischioFromName(name) {
                // analisi rischio RC sempre in posizione 0
                // TODO: andare per id
                if (commonLib.isEmpty(this.analisi_rischio)) return "";

                let indice = this.getIndiceQuestionarioRischio();
                let analisi_rischio_rc = this.analisi_rischio[indice];

                //console.log("analisi_rischio",analisi_rischio_rc);

                if (!commonLib.isEmpty(analisi_rischio_rc)) {
                    var domanda = analisi_rischio_rc.domande.find(d => {
                        return d.name === name;
                    });

                    if (commonLib.isEmpty(domanda)) return "";

                    var risposta =  domanda.value;

                    if (!Array.isArray(risposta))
                        return risposta.toUpperCase();

                    return risposta;
                }

                return "";

            },
            getRispostaAnalisiNecessitafromName(name) {
                if (!commonLib.isEmpty(this.analisi_necessita)) {
                    var domanda = this.analisi_necessita.find(d => {
                        return d.name === name;
                    });

                    // Se la domanda non e` presente nel questionario allora domanda=undefined
                    if (commonLib.isEmpty(domanda)) return "";

                    return domanda.value.toUpperCase();
                }

                return "";
            },
            getRispostaQuestionariFromName(name) {
                var risposta = this.getRispostaAnalisiRischioFromName(name);
                if (commonLib.isEmpty(risposta)) risposta = this.getRispostaAnalisiNecessitafromName(name);

                console.log("risposta:",risposta);

                return risposta;
            },

            getDomandaQuestionariFromName(name) {
                var domanda = this.getDomandaAnalisiNecessitaFromName(name);
                if (commonLib.isEmpty(domanda)) domanda = this.getDomandaAnalisiRischioFromName(name);

                return domanda;
            },
            getDatiResidenzaCliente(campo) {
                if(commonLib.isEmpty(this.dati_cliente)) return "";

                let value = this.dati_cliente.altri_dati[0].indirizzi.filter(v => {
                    return v.tipo === "residenza";
                });

                return value[0][campo];
            }
        },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            let guid_cliente = this.formPreventivo.IDCliente;
            this.dati_cliente = await this.fetchDatiCliente(guid_cliente);
            await this.loadQuestionariAssuntivi(this.quotazione.guid_preventivo);

            this.questionario_caricato = true;
        },
        async activated() {
            /*this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            let guid_cliente = this.formPreventivo.IDCliente;
            this.dati_cliente = await this.fetchDatiCliente(guid_cliente);
            await this.loadQuestionariAssuntivi(this.quotazione.guid_preventivo);*/

            this.questionario_caricato = true;
        }
    }
</script>
